import React, { useState } from 'react';
import MobileHeader from "../../layout/mobile/MobileHeader";
import MobileCarouselBanner from "../../sliders/Mobile";
import Announcement from "../../sliders/Mobile/Announcement";
import GameNavIcon from "../../navigation/mobile/GameNavIcon";
import Footer from "../../layout/mobile/Footer";
import ScrollBanner from "../../common/mobile/ScrollBanner";
import FeatureGames from "../../sliders/Mobile/FeatureGames";
import GuestToolbar from "../../layout/mobile/GuestToolbar";
import Toolbar from "../../navigation/mobile/Toolbar";
import ChatTongole from '../../Chat/ChatTongole';


function Home(){
    const [isLoggedIn] = useState(!!localStorage.getItem('token'));
    return(
       
        <div className="main-router-wrapper ng-tns-c518043276-0 ng-trigger ng-trigger-routeFullPageAni">
                <MobileHeader/>
                <div className="wrap ng-tns-c855548785-1">
                    <div className="content ng-tns-c855548785-1">
                        <div className="ng-tns-c855548785-1 ng-trigger ng-trigger-routeLayoutPageAni">
                               <MobileCarouselBanner/>
                               <Announcement/>
                               <GameNavIcon/>
                               <ScrollBanner/>
                               <FeatureGames/>
                               <ChatTongole/>
                        </div>
                    </div>
                   <Footer/>
                          
                   {isLoggedIn ? (
                   <Toolbar/>
                ) : (
                    <GuestToolbar/>
                )}

                </div>
        </div>
    );


}
export default Home;
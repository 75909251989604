import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const SignUp = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        phone: '',
        email: '',
        referral_code: '',
        currency: 'BDT',
        status: 'active',
    });
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

    useEffect(() => {
        const referralCode = localStorage.getItem('referral_code');
        if (referralCode) {
            setFormData(prevData => ({ ...prevData, referral_code: referralCode }));
        }
    }, []);

    const togglePasswordVisibility = () => setPasswordShown(!passwordShown);
    const toggleConfirmPasswordVisibility = () => setConfirmPasswordShown(!confirmPasswordShown);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let tempErrors = {};
        const usernamePattern = /^[a-zA-Z0-9]+$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^[0-9]{10,15}$/;

        if (!formData.username) tempErrors.username = "Username is required";
        else if (!usernamePattern.test(formData.username)) tempErrors.username = "Invalid username (only letters and numbers)";
        if (!formData.email) tempErrors.email = "Email is required";
        else if (!emailPattern.test(formData.email)) tempErrors.email = "Invalid email address";
        if (!formData.phone) tempErrors.phone = "Phone number is required";
        else if (!phonePattern.test(formData.phone)) tempErrors.phone = "Invalid phone number (10-15 digits)";
        if (!formData.password) tempErrors.password = "Password is required";
        else if (formData.password.length < 8) tempErrors.password = "Password must be at least 8 characters";
        if (!formData.confirmPassword) tempErrors.confirmPassword = "Confirm Password is required";
        else if (formData.confirmPassword !== formData.password) tempErrors.confirmPassword = "Passwords do not match";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Log formData before submitting
        console.log("Submitting formData:", formData);
    
        if (validate()) {
            try {
                const response = await fetch('https://six6.site/api/v1/create-players', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
    
                // Parse response
                const data = await response.json();
    
                // Handle response status
                if (response.ok) {
                    navigate('/login');
                } else {
                    console.error("Server validation errors:", data);
                    // Update errors with server response
                    setErrors(data.errors || { general: "Submission failed" });
                }
            } catch (error) {
                console.error("Error during signup:", error);
                setErrors({ general: "Network error, please try again" });
            }
        } else {
            console.log("Form has errors. Please fix them and try again.");
        }
    };
    

    return (
        <div className="main-router-wrapper ng-trigger ng-trigger-routeFullPageAni">
            <header className="ng-star-inserted member">
                <div className="header-left-btn-group">
                    <Link to={'/'}>
                        <div
                            className="back-btn ng-star-inserted"
                            style={{
                                maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")',
                            }}
                        />
                    </Link>
                </div>
                <div className="header-title ng-star-inserted">Sign up</div>
            </header>
            <div className="wrap">
                <div className="content member-content normal-register">
                    <div className="ng-trigger ng-trigger-routeLayoutPageAni">
                        <div className="ng-star-inserted">
                            <div className="banner">
                                <div className="carousel-wrap siblings ng-star-inserted">
                                    <div className="item-drag">
                                        <div className="item-left">
                                            <div className="item ng-star-inserted" style={{ width: '301px', position: 'absolute', left: '50%', transform: 'translate(-50%)' }}>
                                                <div className="item-pic ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/upload/registerH5Slider/image_162387.jpg")' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="dot-group style-bar">
                                        <li href="#" value={0} className="active ng-star-inserted">
                                            <span className="dot-progress" style={{ animationDuration: '3000ms' }} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inner-wrap">
                                <div className="inner-box">
                                    <form onSubmit={handleSubmit} noValidate className="ng-untouched ng-pristine ng-invalid">
                                        <div className="member-box radius">
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox error">
                                                    <label>Username</label>
                                                    <input
                                                        name="username"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="4-15 char, allow number"
                                                        value={formData.username}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.username && <span className="validation-error text-danger">{errors.username}</span>}
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox password">
                                                    <label>Password</label>
                                                    <input
                                                        name="password"
                                                        type={passwordShown ? "text" : "password"}
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="6-20 Characters and Numbers"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.password && <span className="validation-error text-danger">{errors.password}</span>}
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox password">
                                                    <label>Confirm Password</label>
                                                    <input
                                                        name="confirmPassword"
                                                        type={confirmPasswordShown ? "text" : "password"}
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="confirm password"
                                                        value={formData.confirmPassword}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.confirmPassword && <span className="validation-error text-danger">{errors.confirmPassword}</span>}
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="selectbox ng-star-inserted">
                                                    <label>Currency</label>
                                                    <select
                                                        name="currency"
                                                        formcontrolname="currencyType"
                                                        className="ng-untouched ng-pristine ng-valid"
                                                        value={formData.currency}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="BDT">BDT</option>
                                                        <option value="INR">INR</option>
                                                        <option value="PKR">PKR</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="member-box radius">
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox ng-star-inserted">
                                                    <label>Full Name</label>
                                                    <input
                                                        name="name"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="Full Name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox phone">
                                                    <label>Phone Number</label>
                                                    <div className="phone-area-code">
                                                        <div className="lang-select">
                                                            <button className="btn-select only">
                                                                <li><span>+880</span></li>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <input
                                                        name="phone"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="Phone Number"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.phone && <span className="validation-error text-danger">{errors.phone}</span>}
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox ng-star-inserted">
                                                    <label>E-mail</label>
                                                    <input
                                                        name="email"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="E-mail"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.email && <span className="validation-error text-danger">{errors.email}</span>}
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox ng-star-inserted">
                                                    <label>Referral Code</label>
                                                    <input
                                                        name="referral_code"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-valid"
                                                        placeholder="Enter if you have one"
                                                        value={formData.referral_code}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button className="button">
                                            SUBMIT
                                        </button>
                                        <p>I'm 18 years old, and agree to "terms and conditions".</p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;

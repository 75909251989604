import React from 'react';
import { Link } from 'react-router-dom';

const GuestToolbar = () => {
    return (
        <mcd-guest-toolbar>
            <div className="beforelogin havelanguage">
                <div className="language-select order-1">
                    <img 
                        alt="BD" 
                        src="https://img.j189eb.com/jb/h5/assets/images/flag/BD.png?v=1729676340443&source=mcdsrc" 
                        loading="lazy" 
                    />
                    <p>
                        BDT <br />
                        English
                    </p>
                </div>
                <div className="register-button order-3">
                    <Link to="/signup" className="ng-star-inserted"> Sign up </Link>
                    
                </div>
                <div className="login-button order-2">
                    <Link to="/login" className="ng-star-inserted"> Login </Link>
                    
                </div>
            </div>
        </mcd-guest-toolbar>
    );
};

export default GuestToolbar;

import React from "react";

const PromotionList = () => {
  const promotions = [
    {
      id: "72488",
      title: "৳7,777 Sports Bonus",
      description: "Welcome Gift",
      duration: "2024/05/29 00:00:00 ~ 2024/12/31 23:59:59",
      image: "https://img.j189eb.com/upload/announcement/image_130732.png",
      detailsLink: "/page/guest/register.jsp",
      detailsDataPk: "72488",
      categories: ["4", "99"],
    },
    {
      id: "91864",
      title: "100% Bonus + Extra Free Spins",
      description: "Welcome Gift",
      duration: "2024/10/01 00:00:00 ~ 2024/12/31 23:59:59",
      image: "https://img.j189eb.com/upload/announcement/image_173302.jpg",
      detailsLink: "/page/guest/register.jsp",
      detailsDataPk: "91864",
      categories: ["1", "8", "256", "1024", "8192", "99"],
    },
    {
        id: "91864",
        title: "100% Bonus + Extra Free Spins",
        description: "Welcome Gift",
        duration: "2024/10/01 00:00:00 ~ 2024/12/31 23:59:59",
        image: "https://img.j189eb.com/upload/announcement/image_173302.jpg",
        detailsLink: "/page/guest/register.jsp",
        detailsDataPk: "91864",
        categories: ["1", "8", "256", "1024", "8192", "99"],
      },
    // Add additional promotions as needed
  ];

  return (
    <div className="container-1200">
      <div className="promotion-card-wrap has-join">
        <ul className="filter-container promotion-card three">
          {promotions.map((promo) => (
            <li key={promo.id} className="filtr-item" data-category={promo.categories.join(", ")} title={promo.title}>
              <div className="card-inner">
                <div className="img-wrap">
                  <img src={promo.image} alt={promo.title} />
                </div>
                <div className="explanation-wrap">
                  <div className="line"></div>
                  <div className="tag">Long term</div>
                  <h3>{promo.title}</h3>
                  <p className="text">{promo.description}</p>
                  <p className="time">{promo.duration}</p>
                  <a className="btn-default-xs" href={promo.detailsLink}>Sign Up Now</a>
                  <a className="btn-default-xs detail btn-details" data-pk={promo.detailsDataPk}>Details</a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PromotionList;

import React, { useEffect, useState } from "react";
import axios from "axios";
import EXHeader from "./EXHeader";
import EXNav from "./EXNav";

function InPlay() {
  const [events, setEvents] = useState([]); // All events
  const [inPlayEvents, setInPlayEvents] = useState([]); // Only in-play events
  const [loading, setLoading] = useState(true); // Loader for all events
  const [loadingInPlay, setLoadingInPlay] = useState(false); // Loader for in-play events

  useEffect(() => {
    const fetchAllEvents = async () => {
      setLoading(true);
      try {
        const [cricketResponse, soccerResponse, tennisResponse] = await Promise.all([
          axios.get("https://six6.site/api/sports-events/cricket"),
          axios.get("https://six6.site/api/sports-events/soccer"),
          axios.get("https://six6.site/api/sports-events/tennis"),
        ]);

        const allEvents = [
          ...cricketResponse.data.map((event) => ({ ...event, sport: "cricket" })),
          ...soccerResponse.data.map((event) => ({ ...event, sport: "soccer" })),
          ...tennisResponse.data.map((event) => ({ ...event, sport: "tennis" })),
        ];

        setEvents(allEvents); // Store all events
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllEvents();
  }, []);

  useEffect(() => {
    const fetchInPlayStatuses = async () => {
      if (events.length === 0) return;

      setLoadingInPlay(true); // Start in-play loader
      const cache = new Map();
      const inPlayEventsList = [];

      try {
        await Promise.all(
          events.map(async (event) => {
            if (cache.has(event.MarketId)) {
              if (cache.get(event.MarketId)) {
                inPlayEventsList.push(event);
              }
            } else {
              const response = await axios.get(
                `https://six6.site/api/match-odds/${event.MarketId}`
              );
              const inplay = response.data[0]?.inplay || false;
              cache.set(event.MarketId, inplay);

              if (inplay) {
                inPlayEventsList.push(event);
              }
            }
          })
        );

        setInPlayEvents(inPlayEventsList);
      } catch (error) {
        console.error("Error fetching in-play statuses:", error);
      } finally {
        setLoadingInPlay(false); // Stop in-play loader
      }
    };

    fetchInPlayStatuses();
  }, [events]);

  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading-wrap" style={{ display: "flex" }}>
          <div className="loading">
            <div />
            <div />
          </div>
          <p>Loading events...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="ui-page ui-page-theme-a ui-page-active">
      <EXHeader />
      <div className="tab-wrap">
        <ul>
          <li className="select">
            <a href="#">In-Play </a>
          </li>
          <li>
            <a href="#">Today </a>
          </li>
          <li>
            <a href="#">Tomorrow </a>
          </li>
          <li>
            <a href="#">Result </a>
          </li>
        </ul>
        <a className="a-search" href="#">
          Search
        </a>
      </div>

      <div id="page" role="page">
        <div className="mian-wrap">
          {loadingInPlay && (
            <div className="loading-overlay">
              <div className="loading-wrap" style={{ display: "flex" }}>
                <div className="loading">
                  <div />
                  <div />
                </div>
                <p>Loading in-play events...</p>
              </div>
            </div>
          )}

          <div className="wrap-highlight_list" id="inPlayData">
            {["Cricket", "Soccer", "Tennis"].map((sport) => {
              const sportEvents = inPlayEvents.filter(
                (event) => event.sport === sport.toLowerCase()
              );

              if (sportEvents.length === 0) return null;

              return (
                <div
                  key={sport}
                  id={`inPlayEventType_${sport}`}
                  style={{ display: "block" }}
                >
                  <h3>{sport}</h3>
                  <ul className="highlight-list" id={`inPlayList_${sport}`}>
                    {sportEvents.map((event) => (
                      <li
                        key={event.EventId}
                        id={`inPlayEvent_${event.EventId}`}
                        className="inplay-on"
                      >
                        <a id="info" href="#">
                          <dl>
                            <dt>
                              <span
                                id="fancyBetIcon"
                                className="game-fancy in-play"
                                style={{ display: event.Fancy ? "flex" : "none" }}
                              >
                                <pre>in-play</pre> Fancy
                              </span>
                              <span
                                id="bookMakerIcon"
                                className="game-bookmaker in-play"
                                style={{
                                  display: event.Bookmaker ? "flex" : "none",
                                }}
                              >
                                <pre>in-play</pre> BookMaker
                              </span>
                              <span
                                id={`inPlayStatus_${event.EventId}`}
                                className="time"
                              >
                                In-Play
                              </span>
                            </dt>
                            <dd id="eventName">{event.Event}</dd>
                          </dl>
                        </a>
                        <a
                          id="multiMarketPin"
                          className="pin-off"
                          href="#"
                          title="Add to Multi Markets"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <EXNav />
    </div>
  );
}

export default InPlay;

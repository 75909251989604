import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const ReferralCodePanel = () => {
  const referralCode = "pRQ3QN";
  const referralUrl = `http://www.jeetbuzz.com/?refcode=${referralCode}`;

  return (
   <div className='referral-info__body-column'>
     <div
      className="referral-info__panel referral-info-panel referral-code-panel"
      style={{ backgroundImage: 'url(https://www.jeetbuzz.com/images/v1/web/jb/referral-program/referral-panel-bg-1.png)' }}
    >
      <div className="referral-code-panel-left">
        <div className="referral-code-panel__qrcode" id="qrCodeContainer">
          <QRCodeCanvas value={referralUrl} size={128} />
        </div>
      </div>
      <div className="referral-code-panel-right">
        <div className="referral-code-panel__title">Your Referral Code</div>
        <div className="referral-code-panel__code code-panel-area">
          <span className="code-panel-area__code" data-refercode={referralCode}>
            {referralCode}
            <div className="tips-txt" id="copyCode">Copied</div>
          </span>
          <span
            className="code-panel-area__icon"
            style={{ WebkitMaskImage: 'url(https://www.jeetbuzz.com/images/v1/web/img-template/icon-set/icon-copy.svg)' }}
          />
        </div>
        <div className="referral-code-panel__btn-wrap">
          <div className="invite-friend-box">
            <a className="referral-code-panel__btn btn-default">
              <span>Share</span>
            </a>
            <ul className="invite-friend-list">
              <li className="copy-link" id="shareMenuCopy" data-title="" data-url={referralUrl} style={{ display: 'none' }}>
                <div className="share-link share-copy-link"></div>
                <p>Copy Link</p>
                <div className="tips-txt">Copied</div>
              </li>
              <li data-sharer="facebook" data-url={referralUrl}>
                <div className="share-link share-fb"></div>
                <p>Facebook</p>
              </li>
              <li data-sharer="line" data-url={referralUrl}>
                <div className="share-link share-line"></div>
                <p>Line</p>
              </li>
              <li data-sharer="email" data-url={referralUrl} data-subject="You're invited." data-to="">
                <div className="share-link share-mail"></div>
                <p>Mail</p>
              </li>
              <li data-sharer="skype" data-url={referralUrl}>
                <div className="share-link share-skype"></div>
                <p>Skype</p>
              </li>
              <li data-sharer="telegram" data-url={referralUrl}>
                <div className="share-link share-telegram"></div>
                <p>Telegram</p>
              </li>
              <li data-sharer="whatsapp" data-url={referralUrl}>
                <div className="share-link share-whatsapp"></div>
                <p>Whatsapp</p>
              </li>
            </ul>
          </div>
          <a className="referral-code-panel__btn btn-default" id="copyLink">
            Copy Link
            <span
              className="referral-code-panel__link"
              style={{ WebkitMaskImage: 'url(https://www.jeetbuzz.com/images/v1/web/jb/referral-program/link.svg)' }}
            />
          </a>
        </div>
      </div>
    </div>
   </div>
  );
};

export default ReferralCodePanel;

import React from "react";

function NoData() {
    return (
        <div className="no-result">
            <div className="pic">
                <img alt="no-data" src="https://img.j189eb.com/jb/h5/assets/images/no-data.png?v=1729676340443&source=mcdsrc" loading="lazy" />
            </div>
            <div className="text">No Data</div>
        </div>
    );
}
export default NoData;
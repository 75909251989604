import React, { useState, useEffect } from "react";
import Toolbar from "../../navigation/mobile/Toolbar";
import Footer from "../../layout/mobile/Footer";
import MobileHeader from "../../layout/mobile/MobileHeader";
import { Modal } from 'react-bootstrap';
import NoData from "../../common/mobile/NoData";

function MobilePromotion() {
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPromotion, setSelectedPromotion] = useState(null); // State for selected promotion
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [loadingDetails, setLoadingDetails] = useState(false); // State for loading details

  useEffect(() => {
      const fetchPromotions = async () => {
          try {
              const response = await fetch("https://six6.site/api/promotion");
              if (!response.ok) {
                  throw new Error("Failed to fetch promotions");
              }
              const data = await response.json();
              // Filter promotions based on status
              const filteredPromotions = data.filter(promotion => promotion.status === 'display');
              setPromotions(filteredPromotions);
          } catch (error) {
              console.error("Error fetching promotions:", error);
          } finally {
              setLoading(false);
          }
      };

      fetchPromotions();
  }, []);

  // Function to handle modal open
  const handleShowModal = async (promotionId) => {
      setLoadingDetails(true); // Start loading details
      try {
          const response = await fetch(`https://six6.site/api/promotions/${promotionId}`);
          if (!response.ok) {
              throw new Error("Failed to fetch promotion details");
          }
          const promotionDetails = await response.json();
          setSelectedPromotion(promotionDetails); // Set the selected promotion
      } catch (error) {
          console.error("Error fetching promotion details:", error);
      } finally {
          setLoadingDetails(false); // Stop loading details
          setShowModal(true); // Show the modal
      }
  };

  // Function to handle modal close
  const handleCloseModal = () => {
      setShowModal(false);
      setSelectedPromotion(null); // Reset selected promotion
  };

  // Loading indicator
  if (loading) {
      return <div className="loading">Loading...</div>;
  }

  return (
      <div className="main-router-wrapper">
          <MobileHeader />
          <div className="wrap">
              <div className="content">
                  <div className="content-main">
                      <div className="content-box">
                          <div className="promotion">
                              <div className="tab search-tab">
                                  <ul className="item-ani">
                                      <li className="active">ALL</li>
                                      <li>Welcome Offer</li>
                                      <li>Slots</li>
                                      <li>Live Casino</li>
                                      <li>Sports</li>
                                      <li>Fishing</li>
                                      <li>E-sports</li>
                                      <li>Lottery</li>
                                      <li>P2P</li>
                                      <li>Table</li>
                                      <li>Arcade</li>
                                      <li>Crash</li>
                                      <li>Others</li>
                                  </ul>
                              </div>
                              <div className="promotion-main">
                                  {promotions.length > 0 ? (
                                      promotions.map((promotion) => (
                                          <div className="promotion-box new" key={promotion.id}>
                                              <div className="pic">
                                                  <img src={`https://six6.site/storage/${promotion.thumbnail}`} alt={promotion.name} loading="lazy" />
                                                  <span className="item-bg" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/player/promotion-bg.svg")' }} />
                                              </div>
                                              <div className="promotion-box-inner content-style">
                                                  <div className="text-main">
                                                      <h3>{promotion.name}</h3>
                                                      <p>{promotion.desc ? promotion.desc.split(' ').slice(0, 5).join(' ') : 'No description available'}</p>
                                                  </div>
                                                  <div className="times">
                                                      <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-clock.svg")' }} />
                                                      <span>{` Start: ${new Date(promotion.start_date).toLocaleDateString('en-GB')} ~ End: ${new Date(promotion.end_date).toLocaleDateString('en-GB')}`}</span>
                                                  </div>
                                                  <div className="button-box">
                                                      <div onClick={() => handleShowModal(promotion.id)} className="button btn-primary"><span>Details</span></div>
                                                  </div>
                                              </div>
                                          </div>
                                      ))
                                  ) : (
                                      <NoData/>
                                  )}
                                  <div className="prompt">－end of page－</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <Footer />
          </div>
          <Toolbar />
          <Modal className="msg modal-promotion" show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                  <Modal.Title>{selectedPromotion ? selectedPromotion.name : ''}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  {loadingDetails ? (
                      <p>Loading details...</p> // Loading state
                  ) : (
                      selectedPromotion && (
                          <>
                              <img src={`https://six6.site/storage/${selectedPromotion.thumbnail}`} alt={selectedPromotion.name} className="img-fluid mb-3" />
                              <h5>Description:</h5>
                              <p>{selectedPromotion.desc}</p>
                              <p><strong>Start:</strong> {new Date(selectedPromotion.start_date).toLocaleDateString('en-GB')}</p>
                              <p><strong>End:</strong> {new Date(selectedPromotion.end_date).toLocaleDateString('en-GB')}</p>
                          </>
                      )
                  )}
              </Modal.Body>
              <Modal.Footer>
                  <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
              </Modal.Footer>
          </Modal>
      </div>
  );
}

export default MobilePromotion;

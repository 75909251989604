import React from "react";
function WithdrawNotice() {
     return ( 
        <div className="ng-trigger ng-trigger-slideAnimation ng-star-inserted" style={{}}>
            <div className="tips-info verify-tips tips-info-toggle ng-star-inserted" style={{}}>
                <div className="title-box">
                    <h5>
                        <i className="tips-icon" style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-tips-type02.svg?v=1729676340443")'}} />
                        <span>Please complete the verification below before you proceed with the withdrawal request.</span>
                    </h5>
                    <div className="toggle-btn ng-star-inserted" style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")'}} />
                </div>
                <ol className="tips-info-block active">
                    <li className="personal-info ng-star-inserted">
                        <a className="ng-star-inserted">
                            <label>Personal Info</label>
                            <ul>
                                <li className="ng-star-inserted">Birthday</li>
                            </ul>
                        </a>
                    </li>
                    <li className="contact-info ng-star-inserted">
                        <a className="ng-star-inserted">
                            <label>Contact Info</label>
                            <ul>
                                <li className="ng-star-inserted">Phone Number</li>
                            </ul>
                        </a>
                    </li>
                </ol>
            </div>
        </div>
      ); 
      } 
     export default WithdrawNotice;
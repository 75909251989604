import React from 'react';

const ReferralProgramPromotionGuide = () => {
  const steps = [
    {
      imgSrc: "https://www.jeetbuzz.com/images/v1/web/jb/referral-program/referral-program-flowch-1.png",
      num: "1",
      title: "Send an invitation",
      description: "To start your referral journey",
    },
    {
      imgSrc: "https://www.jeetbuzz.com/images/v1/web/jb/referral-program/referral-program-flowch-2.png",
      num: "2",
      title: "Friend registration",
      description: "with bets placed",
    },
    {
      imgSrc: "https://www.jeetbuzz.com/images/v1/web/jb/referral-program/referral-program-flowch-3.png",
      num: "3",
      title: "Get rewarded daily, weekly, monthly",
      description: "and enjoy with your friends",
    },
  ];

  return (
    <div className="referral-program-promotion__guide referral-program-promotion-guide">
      <div className="referral-program-promotion-guide__title referral-program-promotion-panel__title">
        How to earn more rewards
      </div>
      <div className="referral-program-promotion-guide__flowch referral-program-promotion-flowch">
        {steps.map((step, index) => (
          <div key={index} className="referral-program-promotion-flowch__step referral-program-promotion-step-block">
            <div className="referral-program-promotion-step-block__img">
              <img src={step.imgSrc} alt="flowch-step-icon" />
            </div>
            <div className="referral-program-promotion-step-block__num">{step.num}</div>
            <div className="referral-program-promotion-step-block__body">
              <div className="referral-program-promotion-step-block__arrow referral-program-promotion-step-block__arrow--a1">
                <img src="/images/v1/web/jb/referral-program/arrow-deco.png" alt="arrow-deco" />
              </div>
              <div className="referral-program-promotion-step-block__arrow referral-program-promotion-step-block__arrow--a2">
                <img src="/images/v1/web/jb/referral-program/arrow-deco.png" alt="arrow-deco" />
              </div>
              <div className="referral-program-promotion-step-block__title">{step.title}</div>
              <div className="referral-program-promotion-step-block__descrp">{step.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReferralProgramPromotionGuide;

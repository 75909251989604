import React, { useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import PlayerSideNav from "../../navigation/desktop/PlayerSideNav";
import ProfileView from "../../account/ProfileView";
import BettingRecords from "../../account/BettingRecords"; // Import other views as needed
import TransactionRecords from "../../account/TransactionRecords";
import VIP from "../../account/Vip";
import ResetPassword from "../../account/ResetPassword";
import Inbox from "../../account/Inbox";
import ReferralProgram from "../../account/ReferralProgram";
import Deposit from "../../account/Deposit";
import Turnover from "../../account/Turnover";

function Account() {
  const [activeTab, setActiveTab] = useState("profile"); // Initialize with the "profile" tab

  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return <ProfileView />;
      case "bettingRecords":
        return <BettingRecords />;
      case "transactionRecords":
      return <TransactionRecords />;
      case "vip":
        return <VIP />;
      case "resetPassword":
      return <ResetPassword />;
      case "inbox":
        return <Inbox />;
      case "referralProgram":
        return <ReferralProgram />;
      case "deposit":
        return <Deposit />;
      case "turnover":
      return <Turnover />;
      
      default:
        return <ProfileView />;
    }
  };

  return (
    <>
      <Header />
      <div className="content content-player lv1 disable" style={{ paddingTop: "165px" }}>
        <PlayerSideNav setActiveTab={setActiveTab} /> {/* Pass setActiveTab to PlayerSideNav */}
        {renderContent()} {/* Conditionally render based on activeTab */}
      </div>
      <Footer />
    </>
  );
}

export default Account;

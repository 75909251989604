import React, { useState } from "react";

const ContactServiceBox = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSlider = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`side-slider contact-service-box ${isOpen ? "open" : ""}`}>
      <button className="slide-btn" onClick={toggleSlider}>
        <img
          src="https://www.jeetbuzz.com/images/web/icon-set/customers-slider/cs-title-en.png"
          alt="contact"
        />
      </button>
      <div className="side-slider-content">
        <div className="contact-service-icon">
          <img
            src="https://www.jeetbuzz.com/images/web/icon-set/customers-slider/cs-247.png"
            alt="contact"
          />
        </div>
        <div className="contact-service-logo">
          <img
            src="https://www.jeetbuzz.com/images/web/icon-set/customers-slider/cs_logo.png"
            alt="logo"
          />
        </div>
        <ul id="side-cs-cont" className="contact-service-list">
          <li id="cs-149">
            <a target="_blank" rel="noopener noreferrer">
              <img
                src="https://img.j189eb.com/upload/customerservice/image_2012.png"
                alt="WhatsApp"
              />
              <span>WhatsApp</span>
              <img
                className="qr-code"
                src="https://www.jeetbuzz.com/images/web/icon-set/customers-slider/icon-code-black.png"
                alt="qrcode"
              />
            </a>
            <img
              className="contact-service-qrcode"
              src="https://img.j189eb.com/upload/customerservice/image_1024.png"
              alt="WhatsApp QR Code"
            />
            <hr />
          </li>
          <li id="cs-151">
            <a target="_blank" rel="noopener noreferrer">
              <img
                src="https://img.j189eb.com/upload/customerservice/image_2000.png"
                alt="Facebook"
              />
              <span>Facebook</span>
              <img
                className="qr-code"
                src="https://www.jeetbuzz.com/images/web/icon-set/customers-slider/icon-code-black.png"
                alt="qrcode"
              />
            </a>
            <img
              className="contact-service-qrcode"
              src="https://img.j189eb.com/upload/customerservice/image_2686.png"
              alt="Facebook QR Code"
            />
            <hr />
          </li>
          <li id="cs-150">
            <a href="mailto:support.bd@jeetbuzz.com" target="_blank" rel="noopener noreferrer">
              <img
                src="https://img.j189eb.com/upload/customerservice/image_2018.png"
                alt="Email"
              />
              <span>Email</span>
              <img
                className="qr-code"
                src="https://www.jeetbuzz.com/images/web/icon-set/customers-slider/icon-code-black.png"
                alt="qrcode"
              />
            </a>
            <img
              className="contact-service-qrcode"
              src="https://img.j189eb.com/upload/customerservice/image_1104.jpg"
              alt="Email QR Code"
            />
            <hr />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactServiceBox;

import React from "react";
import Header from "../../layout/Header";
import BannerSlider from "../../sliders/BannerSlider";
import SportEvents from "../../sliders/SportEvents";
import Footer from "../../layout/Footer";
import ContactServiceBox from "../../toggle/ContactServiceBox";
function DHome(){
    return(
       <>
        <Header/>
        <div className="content" style={{ paddingTop: "145px" }}>
            <BannerSlider/>
            <SportEvents/>
            <ContactServiceBox/>
        </div>
        <Footer/>

       </>
    );

}
export default DHome;
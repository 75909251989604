import React, { useState } from "react";
import Logo from "../../common/mobile/Logo";

const Footer = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
    return (
        <div  className="ng-tns-c855548785-1 ng-trigger ng-trigger-footerFadeInAni ng-star-inserted" style={{}}>
       
          <footer  className="footer ng-star-inserted">
           
              <div  className={`footer-seo-content-wrapper ng-star-inserted ${isExpanded ? '' : 'folded'}`} style={{
              height: isExpanded ? "auto" : "200px", 
              
            }}>
                <div >
                  <div  className="ng-star-inserted">
                    <div  className="title">WorldBaji Bangladesh - Your Ultimate Destination for Online Gaming and Betting</div>
                    <div  className="seo-content">
                      <p>
                        Welcome to WorldBaji Bangladesh, where the thrill of online gaming meets the excitement of sports betting, all under one roof. Whether you’re a seasoned gamer or new to the world of
                        online gambling, WorldBaji offers a dynamic platform that caters to all your gaming and betting needs. With a wide array of games from top providers and an innovative betting
                        exchange, we are here to deliver an unparalleled experience to players across Bangladesh.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>A World of Gaming Options</strong><br />
                        At WorldBaji, we pride ourselves on offering a diverse selection of games that appeal to every type of player. Our platform features an extensive lineup of games from
                        industry-leading providers such as I-Sports, JDB, CQ9, Pocket Games, and Evolution. Whether you’re into high-octane slots, classic table games, or immersive live dealer
                        experiences, our collection has something for everyone.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        For fans of live casino action, Sexy and Evolution bring the glamour of the casino floor directly to your screen with professional dealers and real-time gameplay. If you prefer the
                        strategic challenge of poker, BPOKER offers a variety of tables and tournaments to test your skills against players from around the world.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>Explore Innovative Betting Exchanges</strong><br />
                        WorldBaji is not just about casino games; we also offer an exciting betting exchange that allows you to bet directly against other players. This unique feature gives you more
                        control over your bets and often better odds compared to traditional betting platforms. Whether you’re placing a wager on a cricket match, football game, or horse race, our
                        Exchange platform provides you with the tools to make smart, informed bets.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Our sports betting options are powered by top providers like JB Sports, Horsebook, and E1SPORT, ensuring that you have access to the latest odds and a wide range of betting
                        markets. From pre-match bets to live, in-play betting, we cover all the major sports and events, giving you the chance to bet on your favorite teams and athletes.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>Dive into a Diverse Game Collection</strong><br />
                        WorldBaji Bangladesh is home to a vast array of games that cater to every player’s preferences. Our partnership with providers such as Joker, KA, RT, SG, and JILI means that you can
                        explore a rich variety of slots, from high-volatility games with massive jackpots to more casual, low-risk options. Each game is designed with cutting-edge graphics, innovative
                        features, and engaging themes to keep you entertained.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        For players looking for something a bit different, we offer a selection of niche games from providers like SPRIBE, Yellow Bat, HotRoad, and FASTSPIN. These games offer unique
                        gameplay mechanics and fresh, exciting content that stands out from the usual casino offerings.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>Maximize Your Winnings with Bonuses and Promotions</strong><br />
                        At WorldBaji, we believe in rewarding our players. That’s why we offer a variety of bonuses and promotions designed to enhance your gaming experience. New players can take advantage
                        of our generous welcome bonus, while regular players can enjoy ongoing promotions such as deposit matches, free spins, and cashback offers. These bonuses give you extra value and
                        more chances to win big.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Our referral program is another way to boost your rewards. Invite your friends to join WorldBaji, and for every friend who signs up and makes a deposit, you’ll earn bonus credits
                        that can be used to explore our vast game library. This not only increases your potential winnings but also allows you to share the excitement of WorldBaji with others.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>Play with Confidence</strong><br />
                        Your safety and security are our top priorities at WorldBaji. We’ve partnered with reputable providers like PT, WorldMatch, NETENT, and PNG to ensure that all our games are fair and
                        that your personal information is protected. Our platform uses state-of-the-art encryption technology to safeguard your data, so you can play with peace of mind knowing that you’re
                        in a secure environment.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>Join the WorldBaji Bangladesh Community</strong><br />
                        Are you ready to experience the best in online gaming and betting? Join the WorldBaji Bangladesh community today and discover a world of entertainment at your fingertips. Our
                        user-friendly platform is designed to make it easy for you to navigate through our extensive game library, place bets on your favorite sports, and take advantage of our exciting
                        promotions. Don’t forget to invite your friends to join the fun and start earning extra rewards through our referral program. With WorldBaji, the possibilities are endless – start
                        your gaming adventure now and see where the excitement takes you!
                      </p>
                    </div>
                  </div>
                  
                </div>
                <button  onClick={toggleExpand} className="fold-btn button btn-secondary ng-star-inserted">{isExpanded ? "Show Less" : "Show More"}</button>
                
              </div>
              
           
            <div  className="footer-top">
              <div  className="list list--sponsor-data">
                <div  className="list__title">Sponsorships</div>
                <div  className="list__wrap">
                  <div  className="list__item item">
                    <div  className="item__icon">
                      <img  mcdsrc="/assets/images/sponsor/delhi-bulls.png" alt="icon-sponsor" src="https://img.j189eb.com/jb/h5/assets/images/sponsor/delhi-bulls.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                      <div  className="item__content">
                        <div  className="txt">Delhi Bulls</div>
                        <div  className="sub-txt">
                          <span >Title Sponsor</span><span > 2023 - 2024 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div  className="list list--ambassador-data">
                <div  className="list__title">Brand Ambassadors</div>
                <div  className="list__wrap">
                  <div  className="list__item item">
                    <img  mcdsrc="/assets/images/footer/ambassador_signature/andre-russell.png" className="list__item--img" alt="andre-russell" src="https://img.j189eb.com/jb/h5/assets/images/footer/ambassador_signature/andre-russell.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                    <div  className="item__content">
                      <div  className="txt">Andre Dwayne Russell</div>
                      <div  className="sub-txt"><span > 2024 - 2026 </span></div>
                    </div>
                  </div>
                  <div  className="list__item item">
                    <img  mcdsrc="/assets/images/footer/ambassador_signature/mcgrath.png" className="list__item--img" alt="mcgrath" src="https://img.j189eb.com/jb/h5/assets/images/footer/ambassador_signature/mcgrath.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                    <div  className="item__content">
                      <div  className="txt">Glenn McGrat</div>
                      <div  className="sub-txt"><span > 2023 - 2024 </span></div>
                    </div>
                  </div>
                </div>
              </div>
             
                <div  className="pay ng-star-inserted">
                  <h2 >Payment Methods</h2>
                  <ul >
                    <li  className="ng-star-inserted">
                      <img  alt="pay16" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay16.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay22" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay22.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay33" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay33.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay34" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay34.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay45" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay45.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay59" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay59.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay60" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay60.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay61" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay61.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay47" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay47.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <img  alt="pay91" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/pay91.png?v=1729676340443&source=mcdsrc" loading="lazy" className="ng-star-inserted" />
                      
                    </li>
                    
                  </ul>
                </div>
                
             
              
            
                <div  className="safe ng-star-inserted">
                  <h2 >Responsible Gaming</h2>
                  <ul >
                    <li  className="ng-star-inserted">
                      <a  target="_blank" href="https://www.gamcare.org.uk/" className="ng-star-inserted">
                        <img  alt="Gamcare" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/safe/gamcare.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                      </a>
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <a  target="_blank" href="https://jeetbuzzhelp.com/account/18-policy/" className="ng-star-inserted">
                        <img  alt="18 Policy" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/safe/age-limit.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                      </a>
                      
                    </li>
                    
                    <li  className="ng-star-inserted">
                      <a  target="_blank" href="https://jeetbuzzhelp.com/responsible-gaming" className="ng-star-inserted">
                        <img  alt="Responsible Gaming" src="https://img.j189eb.com/jb/h5/assets/images/footer/white/safe/regulations.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                      </a>
                      
                    </li>
                    
                  </ul>
                </div>
            </div>
            <div  className="footer-middle">
              <div  className="gaming-license">
                <h2 >
                  <p >Gaming License</p>
                </h2>
                <ul >
                  <li >
                    <img  mcdsrc="/assets/images/footer/gaming_license.png" alt="gaming_license" src="https://img.j189eb.com/jb/h5/assets/images/footer/gaming_license.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                  </li>
                </ul>
              </div>
              
            </div>
            <div  className="footer-bottom">
              <Logo/>
              <div  className="text">
                <div  className="title">Catch the winning </div>
                <p >© 2024 WorldBaji Copyrights. All Rights Reserved</p>
              </div>
            </div>
          </footer>
      </div>
      
    );
};

export default Footer;

import React from 'react';

const BookmakerMarket = ({ selections }) => {
  return (
    <div id="bookMakerWrap" style={{}}>
  
    <div id="bookMakerMarket_33739483_248688" className="bets-wrap bets-bookmaker" style={{ display: "block" }}>
        <h4>
            <a id="multiMarketPin" className="pin-off" href="#">
                Pin to Muilti Markets
            </a>
            <span>BookMaker</span>
            <p>| Zero Commission</p>
            <a href="#" id="openBookMakerInfo" className="btn-fancy_info" />
            
        </h4>
        <dl className="bets-selections-head">
            <dt />
            <dd className="mode-land" />
            <dd className="mode-land" />
            <dd>Back</dd>
            <dd>Lay</dd>
            <dd className="mode-land" />
            <dd className="mode-land" />
        </dl>
        {selections.map((runner, index) => (
       
       <dl id="bookMakerSelection_33739483_248688_732843" className="bets-selections" style={{ display: "flex" }}>
            <dt>
                <h4 id="runnerName">{runner.runner}</h4>
                <ul>
                    <li id="before" className="win" style={{ display: "block" }} />
                    <li id="after" className="to-lose" style={{ display: "block" }} />
                </ul>
            </dt>
            <dd  className={`${runner.suspended === 1 || runner.ball_running === 1  ? 'suspend-fancy' : ''}`} style={{}}>
                {runner.ball_running === 1 ? (
                    <p>Ball Running</p>
                    ) : runner.suspended === 1 ? (
                        <p>Suspend</p>
                    ) : null}

                   
                </dd>
           
            <dd className="backlay_box">
                <dl className="back-gradient">
                    <dd >
                    <a className="back-1" href="#" fullmarketodds="200">{runner.backPrice}</a>
                    
                    </dd>
                </dl>
                <dl className="lay-gradient">
                    <dd id="lay_1" className>
                    <a className="back-1" href="#" fullmarketodds="200">{runner.layPrice}</a>
                    </dd>
                    
                   
                </dl>
            </dd>
        </dl>
        
       
       ))}
    </div>
</div>
  );
};

export default BookmakerMarket;

import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerSlider = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    pauseOnHover: true,
  };

  const banners = [
    {
      image: "https://img.j189eb.com/upload/announcement/image_68227.jpg",
      title: "Affiliate Program",
      pk: 27718,
    },
    {
      image: "https://img.j189eb.com/upload/announcement/image_113840.jpg",
      title: "Meet Our Newest Brand Ambassador - Andre Dwayne Russell!",
      pk: 66036,
    },
    {
      image: "https://img.j189eb.com/upload/announcement/image_160738.jpg",
      title: "Jili x Jeetbuzz",
      pk: 66504,
    },
    {
      image: "https://img.j189eb.com/upload/announcement/image_147473.jpg",
      title: "Infinite Daily Cash Rewards",
      pk: 78908,
    },
    {
      image: "https://img.j189eb.com/upload/announcement/image_174124.jpg",
      title: "Refer to Earn ৳3,00,000 Monthly",
      pk: 93667,
    },
    {
      image: "https://img.j189eb.com/upload/announcement/image_179060.jpg",
      title: "Seize More Out of Every VIP Point!",
      pk: 96098,
    },
    {
      image: "https://img.j189eb.com/upload/announcement/image_165079.jpg",
      title: "25% Cashback",
      pk: 87150,
    },
  ];

  // Marquee Scrolling State
  const marqueeRef = useRef(null);
  const [position, setPosition] = useState(0);
  const scrollSpeed = 1; // Adjust this value to make the scrolling faster or slower

  useEffect(() => {
    const marqueeWidth = marqueeRef.current.scrollWidth;
    const containerWidth = marqueeRef.current.clientWidth;

    const scroll = () => {
      setPosition((prevPosition) => {
        if (prevPosition <= -marqueeWidth) {
          return containerWidth; // Reset position to create a loop
        }
        return prevPosition - scrollSpeed;
      });
    };

    const intervalId = setInterval(scroll, 20);
    return () => clearInterval(intervalId);
  }, [scrollSpeed]);

  return (
    <div id="main_banner_wrap" className="main-banner-wrap">
      <div className="main-banner">
        <Slider {...sliderSettings} className="slides slick-initialized slick-slider slick-dotted" id="main_announcement_container">
          {banners.map((banner, index) => (
            <div key={index} className="slick-list draggable">
              <div
                className="banner data-image slick-slide"
                data-image={banner.image}
                style={{
                  backgroundImage: `url(${banner.image})`,
                  width: "100%", // Adjust width as necessary
                  height: "400px", // Set a fixed height for banners if needed
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                title={banner.title}
                data-slick-index={index}
              >
                <a data-pk={banner.pk} tabIndex="-1"></a>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Post Section with Marquee */}
      <div className="post">
        <div className="post-icon"></div>
        <div className="marquee" id="marqueeRun" style={{ overflow: "hidden", whiteSpace: "nowrap", position: "relative" }}>
          <ul
            id="main_running_msg_container"
            ref={marqueeRef}
            style={{
              display: "inline-block",
              transform: `translateX(${position}px)`,
              whiteSpace: "nowrap",
            }}
          >
            <li id="running-38991" style={{ display: "inline-block", paddingRight: "50px" }} title="Website Announcement (URL Link)">
              <span ui-key="webContent">
                <p>
                  Dear valued customer, if you have trouble visiting jeetbuzz.com, please visit our backup URLs:
                  https://112233jb.com, https://112233jb.online, https://112233jb.xyz, https://112233jb.live, https://2211133jb.com.
                </p>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;

import React from "react";


const SportsHeader = ({ onSportSelect, selectedSport }) => {
   
    return(
        <div id="highlightLabel" className="highlight-fix">
          <a className="a-search" href="#" onclick="javascript:MobileSearchEventHandler.openSearchWrap();">Search</a>
          <div id="frame" className="wrap-highlight" data-ps-id="2b1b4dd6-ca3b-7997-b158-1aeedfb0f2c6">
            <a className="a-tab_prev" href="#" style={{display: 'none'}}>prev</a>
            <ul id="label" style={{left: '0px'}}>
              <li  onClick={() => onSportSelect('cricket')} id="highlightTab4"  className={`${selectedSport === 'cricket' ? 'select' : ''}`} >
                <span id="tagLive" className="tag-live" style={{}}><strong />6</span>
                <a href="#" neua="Cricket">
                <img className="icon-cricket" src="./icons/exchanges/transparent.gif"/>
                    Cricket </a>
              </li>
              <li  onClick={() => onSportSelect('soccer')} id="highlightTab1" className={`${selectedSport === 'soccer' ? 'select' : ''}`}>
                <span id="tagLive" className="tag-live" style={{}}><strong />17</span>
                <a href="#" neua="Soccer">
                <img className="icon-soccer" src="./icons/exchanges/transparent.gif"/>
                    Soccer </a>
              </li>
              <li onClick={() => onSportSelect('tennis')} id="highlightTab2" className={`${selectedSport === 'tennis' ? 'select' : ''}`} >
                <span id="tagLive" className="tag-live" style={{}}><strong />12</span>
                <a href="#" neua="Tennis">
                <img className="icon-tennis" src="./icons/exchanges/transparent.gif"/>
                    Tennis </a>
              </li>
            </ul>
          </div>
        </div>

    );

}
export default SportsHeader;
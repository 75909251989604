import React, { useState } from 'react';

const ProgramRatioBoard = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="referral-info__body-column">
      <div className="referral-info-rate-board">
        <div className="referral-program__panel referral-program-panel">
          <div className="program-ratio-board">
            <div className="program-ratio-board__head">
              <div className="program-ratio-board__title">Cash Reward Ratio</div>
              <div className="program-ratio-board__sub-title" animatable="animated">
                Turnover Range : More Than 100
              </div>
              <div className="program-ratio-board__navigation program-ratio-board-navigation">
                <div
                  className="program-ratio-board-navigation__prev tab-arrow tab-arrow-pre"
                  onClick={() => handleTabClick(activeTab === 0 ? 2 : activeTab - 1)}
                >
                  <img src="https://www.jeetbuzz.com/images/v1/web/img-template/icon-set/icon-arrow-type03.svg" alt="arrow-prev" />
                </div>
                <div
                  className="program-ratio-board-navigation__next tab-arrow tab-arrow-next"
                  onClick={() => handleTabClick((activeTab + 1) % 3)}
                >
                  <img src="https://www.jeetbuzz.com/images/v1/web/img-template/icon-set/icon-arrow-type03.svg" alt="arrow-next" />
                </div>
              </div>
            </div>
            <div className="program-ratio-board__body tab-wrap">
              <div className="tab-wrap-btn">
                <div
                  className={`tab-wrap__btn ${activeTab === 0 ? 'active' : ''}`}
                  onClick={() => handleTabClick(0)}
                  data-requireturnover="100"
                  data-id="0"
                >
                  tab1
                </div>
                <div
                  className={`tab-wrap__btn ${activeTab === 1 ? 'active' : ''}`}
                  onClick={() => handleTabClick(1)}
                  data-requireturnover="200000"
                  data-id="1"
                >
                  tab2
                </div>
                <div
                  className={`tab-wrap__btn ${activeTab === 2 ? 'active' : ''}`}
                  onClick={() => handleTabClick(2)}
                  data-requireturnover="500000"
                  data-id="2"
                >
                  tab3
                </div>
              </div>

              {/* Tab content */}
              {activeTab === 0 && (
                <div className="program-ratio-board__ul accordion-wrap tab-wrap-item tab-wrap-item-0" style={{ display: 'flex' }}>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 1</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.1%
                      </div>
                    </div>
                  </div>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 2</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.05%
                      </div>
                    </div>
                  </div>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 3</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.01%
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 1 && (
                <div className="program-ratio-board__ul accordion-wrap tab-wrap-item tab-wrap-item-1" style={{ display: 'flex' }}>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 1</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.15%
                      </div>
                    </div>
                  </div>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 2</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.06%
                      </div>
                    </div>
                  </div>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 3</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.02%
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <div className="program-ratio-board__ul accordion-wrap tab-wrap-item tab-wrap-item-2" style={{ display: 'flex' }}>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 1</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.2%
                      </div>
                    </div>
                  </div>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 2</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.07%
                      </div>
                    </div>
                  </div>
                  <div className="program-ratio-board__li program-ratio-board-tier accordion-item" animatable="animated">
                    <div className="program-ratio-board-tier__head">
                      <div className="program-ratio-board-tier__txt">Tier 3</div>
                      <div className="program-ratio-board-tier__value program-ratio-board-tier__value--highlight" data-suffix="(Up to)">
                        0.03%
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramRatioBoard;

import React, { useState } from "react";

function Registration() {
  const [step, setStep] = useState(1); // 1 for step 1, 2 for step 2
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    currencyType: '8',
    friendReferCode: '',
    realName: '',
    callingCode: '880',
    phoneNumber: '',
    email: '',
    captcha: '',
    ageCheck: false,
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle next step or submission
  const handleNextStep = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (step === 1) {
      // Validate step 1 inputs
      if (!formData.username || !formData.password || !formData.confirmPassword) {
        alert("Please fill in all fields.");
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        alert("Passwords do not match.");
        return;
      }
      console.log("Step 1 Data:", formData); // Debugging: Check form data for step 1
      setStep(2); // Move to step 2
    } else {
      // Validate step 2 inputs
      if (!formData.realName || !formData.phoneNumber || !formData.email || !formData.captcha) {
        alert("Please fill in all fields for step 2.");
        return;
      }
      if (!formData.ageCheck) {
        alert("You must confirm that you are 18 years old.");
        return;
      }
      console.log("Form submitted:", formData); // Debugging: Check form data for submission
      alert("Registration Successful!");
      // Optionally, reset the form or redirect after submission
      setFormData({
        username: '',
        password: '',
        confirmPassword: '',
        currencyType: '8',
        friendReferCode: '',
        realName: '',
        callingCode: '880',
        phoneNumber: '',
        email: '',
        captcha: '',
        ageCheck: false,
      });
      setStep(1); // Reset to step 1 if needed
    }
  };

  return (
    <div className="container-1200 container-register">
      <div className="register-wrap">
        <div className="register-notice">
          If you encounter issues, please contact
          <a style={{ color: "red" }} className="intercom_custom_launcher" onClick={(e) => e.preventDefault()}>
            Online CS
          </a>
        </div>

        <div className="left-register-info">
          <ul className="register-tab register-tab-one">
            <li className={step === 1 ? "active" : ""}>
              <a href="javascript:void(0);">Sign up</a>
            </li>
          </ul>
          <div className="general-register step-register">
            <form id="registerForm" novalidate="novalidate" onSubmit={handleNextStep}>
              {step === 1 && (
                <div id="register-form-step1" className="form-inner v2_step1">
                  <ul>
                    <li>
                      <label>Username</label>
                      <input
                        id="username"
                        type="text"
                        name="username"
                        placeholder="4-15 char, allow number"
                        style={{ textTransform: "lowercase" }}
                        aria-required="true"
                        value={formData.username}
                        onChange={handleInputChange}
                      />
                    </li>
                    <li>
                      <label>Password</label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        minLength="6"
                        maxLength="20"
                        placeholder="6-20 char, allow number"
                        aria-required="true"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </li>
                    <li>
                      <label>Confirm Password</label>
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        minLength="6"
                        maxLength="20"
                        placeholder="confirm password"
                        aria-required="true"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                      />
                    </li>
                    <li>
                      <label>Choose Currency</label>
                      <select id="currencyType" name="currencyType" value={formData.currencyType} onChange={handleInputChange}>
                        <option value="8">BDT</option>
                        <option value="7">INR</option>
                        <option value="17">PKR</option>
                      </select>
                    </li>
                    <li>
                      <label htmlFor="friendReferCode">Refer Code</label>
                      <input
                        type="text"
                        id="friendReferCode"
                        name="friendReferCode"
                        value={formData.friendReferCode}
                        minLength="6"
                        maxLength="20"
                        placeholder="Enter if you have one"
                        onChange={handleInputChange}
                      />
                    </li>
                  </ul>
                  <div className="form-btn-box">
                    <button type="submit" className="btn-register-next">Next</button>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div id="register-form-step2" className="form-inner v2_step2">
                  <ul>
                    <li>
                      <label>Full Name</label>
                      <input
                        id="realName"
                        type="text"
                        name="realName"
                        placeholder="Please Input Full Name"
                        maxLength="100"
                        aria-required="true"
                        value={formData.realName}
                        onChange={handleInputChange}
                      />
                      <div className="password-condition">
                        <p>Please enter your full legal name for identity verification during withdrawals.</p>
                      </div>
                    </li>
                    <li>
                      <label>Phone Number</label>
                      <div className="phone-info">
                        <div className="phone-area-code">
                          <select className="vodiapicker" name="callingCodeSelect" value={formData.callingCode} onChange={handleInputChange}>
                            <option data-country="BD" value="880">+880</option>
                          </select>
                        </div>
                        <input
                          id="phoneNumber"
                          type="text"
                          name="phoneNumber"
                          placeholder="Please enter phone number"
                          aria-required="true"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                    </li>
                    <li>
                      <label>Email</label>
                      <input
                        id="email"
                        type="text"
                        name="email"
                        placeholder="input valid email"
                        aria-required="true"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </li>
                    <li id="register-form-code">
                      <label>Verification Code</label>
                      <div className="vode-box input-group">
                        <input
                          id="captchaInput"
                          className="vcode"
                          type="text"
                          name="captcha"
                          placeholder="Enter 4 digit code"
                          aria-required="true"
                          value={formData.captcha}
                          onChange={handleInputChange}
                        />
                        <div className="vcode-img">
                          <img id="captcha" name="captcha" src="/captcha?0.04721662739312116" alt="Captcha" />
                          <button type="button" className="reload" onClick={() => {/* Call your captcha refresh function here */}}>Reload</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="check-wrap">
                    <input
                      type="checkbox"
                      id="ageCheck"
                      name="ageCheck"
                      checked={formData.ageCheck}
                      onChange={handleInputChange}
                      aria-required="true"
                    />
                    <label htmlFor="ageCheck">
                      <span className="check-cube"></span>
                      <p>I'm 18 years old, and agree to "terms and conditions".</p>
                    </label>
                  </div>
                  <div className="form-btn-box">
                    <button type="button" id="goToStep1" className="btn-register-prev" onClick={() => setStep(1)}>
                      Previous
                    </button>
                    <button type="submit" id="registerButton" className="btn-register-submit">Register</button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>

        <div className="right-register-banner">
          <ul className="slides slick-initialized slick-slider slick-dotted" id="register_banner_container">
            <li className="banner data-image slick-slide slick-current slick-active">
              <a data-id="83960" tabIndex="0"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Registration;

import React from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import CasinoSlider from "../../sliders/CasinoSlider";
import GameSearchAndSort from "../../toggle/GameSearchAndSort";
import GameFilter from "../../filtters/desktop/GameFilter";


 function DCasino(){
    return(
        <>
           <Header/>
        <div className="content no-sidenav week-col-" style={{ paddingTop: "145px" }}>
           <CasinoSlider/>
           <GameSearchAndSort/>
           <GameFilter/>
           
        </div>
       
        <Footer/>

        </>
    );

 }
 export default DCasino
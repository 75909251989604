import React from "react";

const OddsTable = ({ selections }) => {
  return (
    <div id="bookMakerWrap" style={{}}>
  
    <div id="bookMakerMarket_33739483_248688" className="bets-wrap bets-bookmaker" style={{ display: "block" }}>
        <h4>
            <a id="multiMarketPin" className="pin-off" href="#">
                Pin to Muilti Markets
            </a>
            <span>Match Odds</span>
            <p>| Zero Commission</p>
            <a href="#" id="openBookMakerInfo" className="btn-fancy_info" />
            
        </h4>
        <dl className="bets-selections-head">
            <dt />
            <dd className="mode-land" />
            <dd className="mode-land" />
            <dd>Back</dd>
            <dd>Lay</dd>
            <dd className="mode-land" />
            <dd className="mode-land" />
        </dl>
        {selections.map((runner, index) => (
       
       <dl id="bookMakerSelection_33739483_248688_732843" className="bets-selections" style={{ display: "flex" }}>
            <dt>
                <h4 id="runnerName">{runner.SelectionName}</h4>
                <ul>
                    <li id="before" className="win" style={{ display: "none" }} />
                    <li id="after" className="to-lose" style={{ display: "none" }} />
                </ul>
            </dt>
            <dd id="suspend" className="" style={{}}>
                
            </dd>
           
            <dd className="backlay_box">
                <dl className="back-gradient">
                    <dd >
                    <a className="back-1" href="#" fullmarketodds="200">{runner.ex.availableToBack[0].price}<span> {runner.ex.availableToBack[0].size}</span></a>
                    

                    </dd>
                </dl>
                <dl className="lay-gradient">
                    <dd id="lay_1" className>
                    <a className="back-1" href="#" fullmarketodds="200">{runner.ex.availableToLay[0].price}<span> {runner.ex.availableToLay[0].size}</span></a>
                    </dd>
                </dl>
            </dd>
        </dl>
        
       
       ))}
    </div>
</div>
  );
};

export default OddsTable;

import React from 'react';
import { NavLink } from 'react-router-dom';

const Toolbar = () => {
    return (
        <div id="toolbar" className="toolbar">
            <ul>
                <li className="home">
                    <NavLink to="/" activeClassName="active">
                        <span 
                            className="item-icon" 
                            style={{
                                backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/toolbar-icon/toolbar-icon-home.svg?v=1729676340443")',
                            }}
                        ></span>
                        <span 
                            className="item-icon-active" 
                            style={{
                                backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/toolbar-icon/toolbar-icon-home.svg?v=1729676340443")',
                            }}
                        ></span>
                        <p>Home</p>
                    </NavLink>
                </li>

                <li className="promotion">
                    <NavLink to="/promotions" activeClassName="active">
                        <span 
                            className="item-icon" 
                            style={{
                                backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/toolbar-icon/toolbar-icon-promotion.svg?v=1729676340443")',
                            }}
                        ></span>
                        <span 
                            className="item-icon-active" 
                            style={{
                                backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/toolbar-icon/toolbar-icon-promotion.svg?v=1729676340443")',
                            }}
                        ></span>
                        <p>Promotions</p>
                    </NavLink>
                </li>

                <li className="deposit">
                    <NavLink to="/deposit" activeClassName="active">
                        <span 
                            className="item-icon" 
                            style={{
                                backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/toolbar-icon/toolbar-icon-deposit.svg?v=1729676340443")',
                            }}
                        ></span>
                        <span 
                            className="item-icon-active" 
                            style={{
                                backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/toolbar-icon/toolbar-icon-deposit.svg?v=1729676340443")',
                            }}
                        ></span>
                        <p>Deposit</p>
                    </NavLink>
                </li>

                <li className="mine">
                    <NavLink to="/account" activeClassName="active">
                        <span 
                            className="item-icon" 
                            style={{
                                backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/toolbar-icon/toolbar-icon-mine.svg?v=1729676340443")',
                            }}
                        ></span>
                        <span 
                            className="item-icon-active" 
                            style={{
                                backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/toolbar-icon/toolbar-icon-mine.svg?v=1729676340443")',
                            }}
                        ></span>
                        <p>My Account</p>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default Toolbar;

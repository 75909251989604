import React, { useState, useEffect } from "react";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";

function BettingRecord() {

    const [activeTab, setActiveTab] = useState("settle");
    
    const filtterOpen = () => {
        const chatElement = document.querySelector('.searchpage');
            chatElement.classList.add('active');
      };
      const filtterClose = () => {
        const chatElement = document.querySelector('.searchpage');
            chatElement.classList.remove('active');
      };

    return (
        <div className="main-router-wrapper ng-tns-c518043276-0 ng-trigger ng-trigger-routeFullPageAni">
            <header id="header" className="login  player">
                <div className="header-left-btn-group">
                   <UniversalBack/>
                </div>
                <div className="header-title ">Betting Records</div>
                <div className="header-right-btn-group">
                    <a name="helpCenter" className="service-btn ">
                        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/header-service-icon.svg?v=1729676340443")' }} />
                        <p className="">Help</p>
                    </a>
                    <div className="editor-btn" style={{ display: 'none', maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-editor.svg?v=1729676340443")' }} />
                </div>
            </header>
            
            <div className="wrap ng-tns-c855548785-1">
                <div className="content ng-tns-c855548785-1 fixed-tab player-content">
                    <div className="ng-tns-c855548785-1 ng-trigger ng-trigger-routeLayoutPageAni">
                    <div className="tab-btn-section">
                        <div className="tab-btn tab-btn-page">
                            <div className="line" style={{
                                width: 'calc(50%)',
                                transform: activeTab === "unsettle" ? 'translate(100%, 0px)' : 'translate(0%, 0px)'
                            }} />
                            <div className={`btn ${activeTab === "settle" ? "active" : ""}`} onClick={() => setActiveTab("settle")}>
                                <div className="text">Settled</div>
                            </div>
                            <div className={`btn ${activeTab === "unsettle" ? "active" : ""}`} onClick={() => setActiveTab("unsettle")}>
                                <div className="text">Unsettled</div>
                            </div>
                        </div>
                    </div>
                        
                        <div className="tab-content tab-content-page ">
                            <div className=" ng-trigger ng-trigger-tabPageTriggerAni">
                                <div className=" ng-trigger ng-trigger-staggerFadeAnimation tab filter-tab ">
                                    <ul className="item-ani ">
                                        <li className="active ">Last 7 days</li>
                                    </ul>
                                    <div onClick={filtterOpen} className="btn search-btn ">
                                        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/games-filter-icon.svg?v=1729676340443")' }} />
                                    </div>
                                </div>
                                <div className="searchpage ">
                                        <div onClick={filtterClose} className="search-top-info ">
                                            <div className="back ">
                                                <span className="item-icon " style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")'}} />
                                                Back
                                            </div>
                                            <input type="text" className=" ng-untouched ng-pristine" placeholder="Betting Record Filter" disabled style={{backgroundImage: 'url("")'}} />
                                        </div>
                                        <div className="searchpage-main ">
                                            <div className="search-checkbox-group check-group ">
                                                <h2 className="">Platform</h2>
                                                <ul >
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >KA Gaming</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Sexy</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Red Tiger</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Spadegaming</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >KingMaker</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Jili</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Saba</label>
                                                    </li>
                                                
                                                </ul>
                                            </div>
                                            <div className="search-checkbox-group check-group ">
                                                <h2 className="">Game Type</h2>
                                                <ul >
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Slots</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Live Casino</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Sports</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Fishing</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Card</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >E-sports</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="checkbox" />
                                                        <label >Lottery</label>
                                                    </li>
                                                
                                                </ul>
                                            </div>
                                            <div className="search-checkbox-group check-group ">
                                                <h2 className="">Date</h2>
                                                <ul >
                                                    <li className="">
                                                        <input  type="radio" />
                                                        <label >Today</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="radio" />
                                                        <label >Yesterday</label>
                                                    </li>
                                                    <li className="">
                                                        <input  type="radio" />
                                                        <label >Last 7 days</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="searchpage-bar ">
                                            <button className="button ">Confirm</button>
                                        </div>
                                    </div>
                                <div className={`${activeTab === "settle" ? 'd-block' : 'd-none'}`} style={{transform: activeTab === "unsettle" ? 'translate(-100%, 0px)' : 'translate(0%, 0px)'}}>
                                    <div className="inner-wrap ">
                                        <div className="inner-box">
                                            <div className="record-item item-title">
                                                <div className="item platform">Platform</div>
                                                <div className="item type">Game Type</div>
                                                <div className="item bet">Turnover</div>
                                                <div className="item profit">Profit/Loss</div>
                                            </div>
                                            <div className="no-result">
                                                <div className="pic">
                                                    <img alt="no-data" src="https://img.j189eb.com/jb/h5/assets/images/no-data.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                                                </div>
                                                <div className="text">No Data Settle</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${activeTab === "unsettle" ? 'd-block' : 'd-none'}`} style={{transform: activeTab === "unsettle" ? 'translate(0%, 0px)' : 'translate(-100%, 0px)'}}>
                                    <div className="inner-wrap ">
                                        <div className="inner-box">
                                            <div className="record-item item-title">
                                                <div className="item platform">Platform</div>
                                                <div className="item type">Game Type</div>
                                                <div className="item bet">Turnover</div>
                                                <div className="item profit">Profit/Loss</div>
                                            </div>
                                            <div className="no-result">
                                                <div className="pic">
                                                    <img alt="no-data" src="https://img.j189eb.com/jb/h5/assets/images/no-data.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                                                </div>
                                                <div className="text">No Data unSettle</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                </div>
                <div className="ng-tns-c855548785-1 ng-trigger ng-trigger-footerFadeInAni "></div>
            </div>
            <Toolbar/>
        </div>
    );
}

export default BettingRecord;

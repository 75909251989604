// SportEvents.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SportEvents = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 100,
    arrows: true,
  };

  const leftEvents = [
    {
      id: 61071,
      title: "South Africa tour of Bangladesh",
      image: "https://img.j189eb.com/upload/announcement/image_181470.jpg",
    },
    {
        id: 61072,
        title: "South Africa tour of Bangladesh",
        image: "https://img.j189eb.com/upload/announcement/image_181470.jpg",
      },
  ];

  const rightEvents = [
    {
      id: 60350,
      title: "South Africa tour of Bangladesh",
      image: "https://img.j189eb.com/upload/announcement/image_181641.jpg",
    },
  ];

  return (
    <div className="container-1200">
      <div className="main-wrap-sport-1st">
        {/* Left Sport Event */}
        <div id="main_wrap_sport_left" className="main-wrap-sport left">
          <h3>Favourites</h3>
          <div className="sport-event">
            <Slider {...sliderSettings} className="slides slick-initialized slick-slider" id="main_sport_slider_container_left">
              {leftEvents.map((event) => (
                <div key={event.id} id={`sportslider-${event.id}`} className="slick-slide slick-current slick-active" data-id={event.id} title={event.title} data-slick-index="0" aria-hidden="false" tabIndex="0">
                  <div className="sport-img data-image">
                    <img src={event.image} alt={event.title} style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="navigation-1">
            <a className="flex-next slick-arrow" aria-disabled="true" tabIndex="-1">next</a>
            <a className="flex-prev slick-arrow" aria-disabled="true" tabIndex="-1">prev</a>
          </div>
        </div>

        {/* Right Sport Event */}
        <div id="main_wrap_sport_right" className="main-wrap-sport right">
          <h3>Favourites</h3>
          <div className="sport-event">
            <Slider {...sliderSettings} className="slides slick-initialized slick-slider" id="main_sport_slider_container_right">
              {rightEvents.map((event) => (
                <div key={event.id} id={`sportslider-${event.id}`} className="slick-slide slick-current slick-active" data-id={event.id} title={event.title} data-slick-index="0" aria-hidden="false" tabIndex="0">
                  <div className="sport-img data-image">
                    <img src={event.image} alt={event.title} style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="navigation-2">
            <a className="flex-next slick-arrow" aria-disabled="true" tabIndex="-1">next</a>
            <a className="flex-prev slick-arrow" aria-disabled="true" tabIndex="-1">prev</a>
          </div>
        </div>
      </div>

      {/* Hidden Template */}
      <div style={{ display: "none" }}>
        <ul>
          <li id="main_wrap_sport_template" data-id="">
            <div className="sport-img data-image">
              <img src="" alt="Sport Template" style={{ width: "100%", height: "100%" }} />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SportEvents;

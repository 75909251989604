import React from "react";
import EXHeader from "./EXHeader";
import EXNav from "./EXNav";
function ExAccount() {
    return (
        <div id="page" role="page">
        <EXHeader/>
  
  <div className="mian-wrap">
    <div className="path-wrap">
      <p className="account-id">
        <span>jbpm000008223168 </span>
        <span className="time-zone">GMT+5:30</span>
      </p>
    </div>
    <ul className="menu-list">
      <li className><a href="#" target="_blank">My Profile </a></li>
      <li className><a href="#" target="_blank">Balance Overview </a></li>
      <li className><a href="#" target="_blank">My Bets </a></li>
      <li className><a href="#" target="_blank">Bets History </a></li>
      <li className><a href="#" target="_blank">Profit &amp; Loss </a></li>
      <li className><a href="#" target="_blank">Activity Log </a></li>
    </ul>
  </div>
  <EXNav/>
</div>
    );
}
export default ExAccount;
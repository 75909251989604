import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import OddsTable from "./OddsTable";
import BookmakerMarket from "./BookmakerMarket";
import FancyBetTable from "./FancyBetTable";
import EXHeader from "./EXHeader";
import EXNav from "./EXNav";

const MatchOdds = () => {
  const [marketData, setMarketData] = useState(null);
  const [fancyBets, setFancyBets] = useState(null);
  const [bookmakerData, setBookmakerData] = useState(null);
  const [iframeUrl, setIframeUrl] = useState(null); // State to store the iframe URL
  const [userIP, setUserIP] = useState(null); // State to store user IP
  const location = useLocation();
  const { marketId, eventId, selectedSport, previousPath } = location.state || {};

  const POLLING_INTERVAL = 300; // Polling interval in milliseconds

  useEffect(() => {
    let polling;

    // Function to fetch user IP
    const fetchUserIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setUserIP(response.data.ip);
      } catch (error) {
        console.error("Error fetching user IP:", error);
      }
    };

    // Function to fetch market and event data
    const fetchMarketData = async () => {
      try {
        const marketResponse = await axios.get(`https://six6.site/api/match-odds/${marketId}`);
        setMarketData(marketResponse.data[0]);

        const eventResponse = await axios.get(`https://six6.site/api/event/${eventId}`);
        const eventData = eventResponse.data.data.items[eventId];

        setFancyBets(eventData.fancy2);

        const bookmakerKeys = Object.keys(eventData.bookmaker);
        if (bookmakerKeys.length > 0) {
          const dynamicBookmakerKey = bookmakerKeys[0];
          setBookmakerData(eventData.bookmaker[dynamicBookmakerKey]);
        }
      } catch (error) {
        console.error("Error fetching market or event data:", error);
      }
    };

    // Function to fetch the iframe URL
    const fetchIframeUrl = async () => {
      try {
        const response = await axios.get(`https://six6.site/api/score/board/${eventId}`, {
          responseType: "text", // Ensure we receive raw HTML as text
        });
        const htmlText = response.data;

        // Parse the HTML to extract the iframe URL
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlText, "text/html");
        const scriptContent = Array.from(doc.querySelectorAll("script"))
          .map((script) => script.innerHTML)
          .find((content) => content.includes("var iframeUrl"));

        if (scriptContent) {
          // Extract the URL from the JavaScript code
          const match = scriptContent.match(/var iframeUrl = '(.*?)';/);
          if (match && match[1]) {
            setIframeUrl(match[1]);
          }
        }
      } catch (error) {
        console.error("Error fetching or parsing iframe URL:", error);
      }
    };

    fetchUserIP(); // Fetch user IP
    fetchMarketData(); // Fetch market and event data
    fetchIframeUrl(); // Fetch iframe URL
    polling = setInterval(fetchMarketData, POLLING_INTERVAL);

    return () => clearInterval(polling);
  }, [marketId, eventId]);

  if (!marketData) {
    return(
      <div className="loading-overlay">
  <div className="loading-wrap" style={{display: 'flex'}}>
    <div className="loading">
      <div />
      <div />
    </div>
    <p>Loading...</p>
  </div>
</div>

    );
  }

  // Construct live streaming URL with user IP
  const streamUrl = userIP
    ? `https://e765432.xyz/static/69fb31e65e4ed5d6eaebf3b8b0e0e6a715c77cc6/getdata.php?chid=7108&userip=${userIP}`
    : null;

  return (
    <div data-role="page">
     <EXHeader/>

      <div id="page" role="page">
        <div id="mainWrap" className="mian-wrap" eventtype={4} eventid={eventId} marketid={marketId}>
          <div className="game-wrap">
            <h4 id="gameInfo" className="game-info">
              Cricket
              <ul id="infoIcon" className="info-icon">
                <li id="inPlayIcon" style={{}}>
                  <img className="info-inplay" src="./icons/exchanges/transparent.gif" alt="In-Play" />
                  In-Play
                </li>
              </ul>
            </h4>
          </div>

          {/* Dynamic Iframe for Scores */}
          

          {iframeUrl ? (
            <div style={{ margin: "0" }}>
              <iframe
                src={iframeUrl}
                title="Live Score"
                width="100%"
                height="400"
                frameBorder="0"
                style={{
                  border: "none",
                  overflow: "hidden", // Ensures no scrollbars in the iframe
                }}
                scrolling="no" // Prevents iframe scrollbars
              ></iframe>
            </div>
          ) : (
            <p>Loading live score...</p>
          )}

          {/* Odds Table */}
          <OddsTable selections={marketData.runners} />

          {/* Bookmaker Market */}
          {bookmakerData && <BookmakerMarket selections={bookmakerData.runners} />}

          {/* Fancy Bets Table */}
          {fancyBets && <FancyBetTable fancyBets={Object.values(fancyBets)} />}
        </div>
      </div>
      <EXNav/>
    </div>
  );
};

export default MatchOdds;

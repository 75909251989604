import React from 'react';

const FeatureGames = () => {
    return (
        <mcd-feature-games>
            <div className="recommend feature-games">
                <div className="recommend-title">
                    <h2>Featured Games</h2>
                </div>
                <div className="recommend-bg games">
                    <div className="recommend-main games-main">
                        <div className="games-box">
                            <div className="pic">
                                <a href="#">
                                    <img 
                                        alt="Super Ace" 
                                        src="https://img.j189eb.com/upload/game/AWCMJILI/JILI-SLOT-027.png" 
                                        loading="lazy" 
                                    />
                                </a>
                            </div>
                            <div className="text">
                                <h3>Super Ace</h3>
                            </div>
                        </div>
                        <div className="games-box">
                            <div className="pic">
                                <a href="#">
                                    <img 
                                        alt="Money Coming" 
                                        src="https://img.j189eb.com/upload/game/AWCMJILI/JILI-SLOT-029.png" 
                                        loading="lazy" 
                                    />
                                </a>
                            </div>
                            <div className="text">
                                <h3>Money Coming</h3>
                            </div>
                        </div>
                        <div className="games-box">
                            <div className="pic">
                                <a href="#">
                                    <img 
                                        alt="Boxing King" 
                                        src="https://img.j189eb.com/upload/game/AWCMJILI/JILI-SLOT-031.png" 
                                        loading="lazy" 
                                    />
                                </a>
                            </div>
                            <div className="text">
                                <h3>Boxing King</h3>
                            </div>
                        </div>
                        <div className="games-box">
                            <div className="pic">
                                <a href="#">
                                    <img 
                                        alt="Cricket Sah 75" 
                                        src="https://img.j189eb.com/upload/game/AWCMJILI/JILI-SLOT-082.png" 
                                        loading="lazy" 
                                    />
                                </a>
                            </div>
                            <div className="text">
                                <h3>Cricket Sah 75</h3>
                            </div>
                        </div>
                        <div className="games-box">
                            <div className="pic">
                                <a href="#">
                                    <img 
                                        alt="Boxing King" 
                                        src="https://img.j189eb.com/upload/game/AWCMJILI/JILI-SLOT-031.png" 
                                        loading="lazy" 
                                    />
                                </a>
                            </div>
                            <div className="text">
                                <h3>Boxing King</h3>
                            </div>
                        </div>
                        <div className="games-box">
                            <div className="pic">
                                <a href="#">
                                    <img 
                                        alt="Fortune Gems 2" 
                                        src="https://img.j189eb.com/upload/game/AWCMJILI/JILI-SLOT-076.png" 
                                        loading="lazy" 
                                    />
                                </a>
                            </div>
                            <div className="text">
                                <h3>Fortune Gems 2</h3>
                            </div>
                        </div>
                        <div className="games-box">
                            <div className="pic">
                                <a href="#">
                                    <img 
                                        alt="Wild Bounty Showdown" 
                                        src="https://img.j189eb.com/upload/game/PG/135.png" 
                                        loading="lazy" 
                                    />
                                </a>
                            </div>
                            <div className="text">
                                <h3>Wild Bounty Showdown</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mcd-feature-games>
    );
};

export default FeatureGames;

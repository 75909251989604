import React, { useState, useEffect } from "react";
import DepositNotice from "../../notice/mobile/DepositNotice";
import WithdrawNotice from "../../notice/mobile/WithdrawNotice";
import { Link } from "react-router-dom";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";

function Profile() {
    const [userData, setUserData] = useState({
        username: '',
        vip_coin: 0,
        name: '',
        phone: '',
        email: '',
        created_at: '',
        dob: '',
    });
    const [isEditingBirthday, setIsEditingBirthday] = useState(false);
    const [verifyType, setVerifyType] = useState("");

    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');
        if (token && remoteId) {
            try {
                const response = await fetch(`https://six6.site/api/user/show?remote_id=${remoteId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const formattedDate = data.created_at ? new Date(data.created_at).toLocaleDateString('en-GB') : 'N/A';
                    
                    setUserData({
                        username: data.username || 'Unknown User',
                        vip_coin: data.vip_coin || 0,
                        name: data.name || 'N/A',
                        phone: data.phone || '',
                        email: data.email || '',
                        created_at: formattedDate,
                        dob: data.dob || '',
                    });
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const OpenPOP = (type) => {
        setVerifyType(type);
        const chatElement = document.querySelector('.pop-get-vcode');
        chatElement.classList.add('active');
        const bg = document.querySelector('.pop-bg');
        bg.classList.add('d-block');
    };

    const ClosePOP = () => {
        setVerifyType("");
        const chatElement = document.querySelector('.pop-get-vcode');
        chatElement.classList.remove('active');
        const bg = document.querySelector('.pop-bg');
        bg.classList.remove('d-block');
    };

    return (
        <div className="main-router-wrapper ng-trigger ng-trigger-routeFullPageAni">
            <header id="header" className="login ng-star-inserted player">
                <div className="header-left-btn-group">
                   <UniversalBack/>
                    
                </div>
                <div className="header-title ng-star-inserted">Personal Info</div>
            </header>
            <div className="wrap">
                <div className="content player-content">
                    <div className="ng-trigger ng-trigger-routeLayoutPageAni">
                        <div className="player-vip-lv1 ng-star-inserted">
                            <div className="player-info-vip" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/vip/vip-card-bg-1.jpg?v=1729676340443")' }}>
                                <div className="member-pic">
                                    <span className="item-icon ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/vip/bdt/normal.png?v=1729676340443")' }} />
                                </div>
                                <div className="member-label" style={{ backgroundImage: 'url("PROFILE_VIP_LABEL_LV_1")' }} />
                            </div>
                            <div className="membername-wrap">
                                <div className="membername">{userData.username}</div>
                                <div className="level ng-star-inserted">Normal</div>
                            </div>
                        </div>
                        <div id="profile-vip-div" className="menu-box ng-star-inserted">
                            <div className="vip-area-group">
                                <div className="left-box">
                                    <div className="item">
                                        <h3>VIP Points (VP)</h3>
                                        <div className="points-number">{userData.vip_coin}</div>
                                    </div>
                                </div>
                                <div className="right-box">
                                    <a className="goto-myvip ng-star-inserted" href="/bd/en/member/vip-points-exchange">
                                        <div className="myvip-text">
                                            <p>My VIP</p>
                                            <span className="item-icon" style={{ maskImage: 'url("/assets/images/icon-set/player/vip/icon-arrow.svg")' }} />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <DepositNotice />
                        <WithdrawNotice />
                        <div className="menu-box ng-star-inserted">
                            <div className="area-group full_name">
                                <div className="item">
                                    <h3>Full Name</h3>
                                    <div className="tip">Date Registered: {userData.created_at}</div>
                                </div>
                                <div className="text">
                                    <span className="ng-star-inserted">{userData.name}</span>
                                </div>
                            </div>
                            <div className="input-group personal-phone-group">
                                <label htmlFor="other">Phone Number
                                    <div onClick={() => OpenPOP('phone')} className="add-btn">
                                        <div className="add-btn-icon" />
                                        <span>Add</span>
                                    </div>
                                </label>
                                <div className="input-wrap ng-star-inserted">
                                    <input type="text" disabled className="personal-number default" defaultValue={userData.phone} />
                                    <div
                                        className="status unconfirm-btn ng-star-inserted"
                                        onClick={() => OpenPOP('unverifiedphone')}
                                    >
                                        Not Verified
                                    </div>
                                </div>
                            </div>
                            <div className="input-group">
                                <label htmlFor="other">Email</label>
                                <div className="input-wrap ng-star-inserted">
                                    <input type="text" id="email" className="email profile ng-untouched ng-pristine" defaultValue={userData.email} disabled />
                                    <div
                                        className="status unconfirm-btn ng-star-inserted"
                                        onClick={() => OpenPOP('email')}
                                    >
                                        Not Verified
                                    </div>
                                </div>
                            </div>
                            <div className="input-group">
                                <label htmlFor="other">Birthday</label>
                                <div className="input-wrap">
                                    <input
                                        type="date"
                                        placeholder="YYYY/MM/DD"
                                        disabled={!isEditingBirthday}
                                        className="ng-star-inserted"
                                        defaultValue={userData.dob}
                                    />
                                    <div onClick={() => setIsEditingBirthday(!isEditingBirthday)} className="status save-btn" style={{ display: 'block' }}>
                                        {isEditingBirthday ? 'Save' : 'Edit Birthday'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="member-menu-point ng-star-inserted">
                            <i>
                                <span className="item-icon" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-customer.png?v=1729676340443")' }} />
                            </i>
                            <p>For privacy and security, Information can’t be modified after confirmation. Please
                                <span name="liveChatBtn">contact customer service</span>.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="pop-get-vcode">
                    <a className="btn-closed" onClick={ClosePOP} style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-cross-type01.svg?v=1729676340443")' }} />
                    <form noValidate className="ng-untouched ng-pristine ng-invalid">
                        <div className="menu-box">
                            <div className="input-group">
                                <label htmlFor="other">
                                    {verifyType === 'phone' && 'New Phone Number'}
                                    {verifyType === 'unverifiedphone' && 'Verify Phone Number'}
                                    {verifyType === 'email' && 'Verify Email'}
                                </label>
                                <div className="input-wrap">
                                    {verifyType === 'phone' && (
                                        <div className="input-wrap phone-wrap">
                                            <div className="phone-area-code">
                                                <div className="lang-select">
                                                    <button className="btn-select only">
                                                        <li><span>+880</span></li>
                                                    </button>
                                                </div>
                                            </div>
                                            <input type="number" name="phone" className="ng-untouched ng-pristine ng-invalid" />
                                        </div>
                                    )}
                                    {verifyType === 'email' && (
                                        <div className="input-wrap">
                                            <input type="email" name="email" className="ng-untouched ng-pristine ng-invalid" />
                                        </div>
                                    )}
                                    {verifyType === 'unverifiedphone' && (
                                        <div className="input-wrap phone-wrap">
                                            <div className="phone-area-code">
                                                <div className="lang-select">
                                                    <button className="btn-select only">
                                                        <li><span>+880</span></li>
                                                    </button>
                                                </div>
                                            </div>
                                            <input type="number" name="phone" defaultValue={userData.phone} className="ng-untouched ng-pristine ng-invalid" />
                                        </div>
                                    )}
                                </div>
                                <br />
                                <div type="submit" className="button">Submit</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="pop-bg"></div>
            </div>
            <Toolbar/>
        </div>
    );
}

export default Profile;

import React from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import Registration from "../../common/Registration";

function Registion(){
    return(
        <>
        <Header/>
        <div className="content" style={{ paddingTop: "145px" }}>
            <Registration/>
            </div>
        <Footer/>
        </>
    );

}
export default Registion;
import React, { useState, useEffect } from "react";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";
import { Modal, Button } from "react-bootstrap"; 


function MobileInbox() {
    const [inboxMessages, setInboxMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [fullMessage, setFullMessage] = useState('');
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const isLoggedIn = !!localStorage.getItem('token');

    // Fetch Inbox Messages
    const fetchInboxMessages = async (page) => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');

        try {
            const response = await fetch(`https://six6.site/api/inbox?remote_id=${remoteId}&page=${page}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (data.success) {
                setInboxMessages(data.data.data);
                setTotalPages(data.data.last_page);
            }
        } catch (error) {
            console.error("Error fetching inbox messages:", error);
        }
    };

    // Fetch Full Message for Modal
    const fetchFullMessage = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://six6.site/api/inbox/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (data.success) {
                setFullMessage(data.data);
                setShowModal(true);
            }
        } catch (error) {
            console.error("Error fetching full message:", error);
        }
    };

    // Toggle edit mode
    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    // Toggle select message
    const toggleSelectMessage = (id) => {
        setSelectedMessages((prev) =>
            prev.includes(id) ? prev.filter((msgId) => msgId !== id) : [...prev, id]
        );
    };

    // Mark messages as read
    const markMessagesAsRead = async () => {
        const token = localStorage.getItem('token');
        await Promise.all(
            selectedMessages.map(async (id) => {
                try {
                    await fetch(`https://six6.site/api/inbox/${id}/read`, {
                        method: 'PATCH',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                } catch (error) {
                    console.error("Error marking message as read:", error);
                }
            })
        );
        setSelectedMessages([]);
        fetchInboxMessages(currentPage);
    };

    // Delete selected messages
    const deleteMessages = async () => {
        const token = localStorage.getItem('token');
        await Promise.all(
            selectedMessages.map(async (id) => {
                try {
                    await fetch(`https://six6.site/api/inbox/${id}`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                } catch (error) {
                    console.error("Error deleting message:", error);
                }
            })
        );
        setSelectedMessages([]);
        fetchInboxMessages(currentPage);
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchInboxMessages(currentPage);
            const interval = setInterval(() => fetchInboxMessages(currentPage), 10000);
            return () => clearInterval(interval);
        }
    }, [isLoggedIn, currentPage]);

    const OpenPOP = () => {
        const chatElement = document.querySelector('.editor-menu');
        chatElement.classList.add('show');
        const bg = document.querySelector('.pop-bg');
        bg.classList.add('d-block');
    };
    const OpenCheck = () => {
        const chatElement = document.querySelector('.editor-check');
        chatElement.classList.add('show');
       
    };
    const closeCheck = () => {
        const chatElement = document.querySelector('.editor-check');
        chatElement.classList.remove('show');
       
    };

    const ClosePOP = () => {
        const chatElement = document.querySelector('.editor-menu');
        chatElement.classList.remove('show');
        const bg = document.querySelector('.pop-bg');
        bg.classList.remove('d-block');
    };
    return (
        <div className="main-router-wrapper">
            <header id="header" className="login player">
                <div className="header-left-btn-group">
                   <UniversalBack/>
                    
                </div>
                <div className="header-title">Inbox</div>
               
                <div className="header-right-btn-group">
                    <div
                    onClick={OpenPOP}
                        className="editor-btn"
                        style={{ display: "block", maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-editor.svg?v=1729676340443")' }}
                    ></div>
                </div>
            </header>

            <div className="wrap">
                <div className="content player-content">
                    <div className="tab-btn-section"></div>
                    <div className="tab-content tab-content-page">
                        <div className="inner-box">
                            <div className={`list list-message ${isEditMode ? 'editor-active' : ''}`}>
                                <div className="date-title">
                                    <div className="date">
                                        <span
                                            className="item-icon"
                                            style={{
                                                maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-calendar-type02.svg?v=1729676340443")',
                                            }}
                                        ></span>
                                        2024/10/26
                                    </div>
                                    <div className="time-zone">GMT+6</div>
                                </div>
                                <div className="list-content">
                                    <ul>
                                    {inboxMessages.map((message) => {
                                        const isChosen = selectedMessages.includes(message.id);
                    const messagePreview = message.message.split(' ').slice(0, 5).join(' ') + (message.message.split(' ').length > 5 ? '...' : '');
                    return (
                                        <li 
                                        className={`message-item ${isChosen ? 'chosed' : ''} ${message.is_read ? 'read' : ''}`}
                                        onClick={() =>  isEditMode ? toggleSelectMessage(message.id) : fetchFullMessage(message.id) && markMessagesAsRead() }
                                        
                                       >
                                           
                                            <div
                                            onClick = {()=> isEditMode ? OpenCheck() :null}
                                                className="chose-btn"
                                                style={{
                                                    maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-check-type04.svg?v=1729676340443")',
                                                }}
                                            ></div>
                                            <div className="icon">
                                                <img
                                                    alt="icon-speaker"
                                                    src="https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-speaker.svg?v=1729676340443"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div className="content-wrap">
                                                <div className="title">
                                                    <span>{message.title}</span>
                                                    <div className="msg-time">{message.created_at}</div>
                                                </div>
                                                <div className="text">
                                                {messagePreview}
                                                </div>
                                            </div>
                                        </li>
                                         );
                                        })}
                                    </ul>
                                </div>
                                <div className="prompt">－end of page－</div>
                            </div>
                        </div>
                        <div className="pop-wrap pop-editor">
                            <ul className="editor-menu">
                            <li onClick={toggleEditMode}>Edit</li>
                                <li onClick={markMessagesAsRead}>Read</li>
                                <li onClick={ClosePOP}>Cancel</li>
                            </ul>
                            <ul className={`editor-check`}>
                            <li onClick={() => { markMessagesAsRead(); closeCheck(); }}>Read</li>
                            <li onClick={() => { deleteMessages(); closeCheck(); }}>Delete</li>
                            </ul>
                        </div>
                        
                        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{fullMessage.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{fullMessage.message}</p>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
            <div onClick={ClosePOP} className="pop-bg" />
            <Toolbar />
        </div>
    );
}

export default MobileInbox;

import React, { useState } from "react";

const tabs = [
  { id: "vipInfo", label: "My VIP" },
  { id: "vipReceived", label: "VP Received" },
  { id: "vipUsed", label: "VP Used" },
  { id: "vipHistory", label: "VIP History" },
];

const VIP = () => {
  const [activeTab, setActiveTab] = useState("vipInfo");

  const handleTabClick = (event, tab) => {
    event.preventDefault();
    setActiveTab(tab);
  };

  const activeIndex = tabs.findIndex((tab) => tab.id === activeTab);
  const sliderPosition = activeIndex * 160; // Assuming each tab is 160px wide

  return (
    <div className="main-content-wrap">
      <div className="tips-info verify-tips" style={{ display: "none" }}>
        <div className="tips-title-box">
          <h6>
            <p>Please complete the verification below before you proceed with the withdrawal request.</p>
          </h6>
          <div className="close-tips"></div>
        </div>
        <ol>
          <li className="info-personal">
            <label>Personal Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Birthday</li>
              </div>
            </ul>
          </li>
          <li className="info-contact">
            <label>Contact Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Phone Number</li>
              </div>
            </ul>
          </li>
        </ol>
      </div>

      <div className="top-overview-wrap">
        <ul>
          <li>
            <div className="left-overview-area">
              <label>Main Wallet</label>
              <div className="total-money totalBalanceWallet">
                <span className="balance" style={{ paddingRight: "7px" }}>৳</span>
                <p className="balance">0</p>
              </div>
            </div>
          </li>
          <li style={{ display: "none" }}>
            <div id="topOverviewTotalBonusWallet" className="left-overview-area">
              <label>Bonus Wallet</label>
              <div className="total-money totalBonusWallet">
                <span className="balance" style={{ paddingRight: "7px" }}>৳</span>
                <p>0</p>
              </div>
            </div>
          </li>
          <li>
            <div className="left-overview-area danger-state">
              <label>Security Level</label>
              <p id="contactVerifyStatus">Unverified</p>
            </div>
            <div className="right-overview-area">
              <a id="verify-mail-link" className="verify-mail status-verify-no"></a>
              <a id="verify-phone-link" className="verify-phone status-verify-no"></a>
            </div>
          </li>
        </ul>
      </div>

      <div id="mainContentBody" className="replaceable-block">
        <main className="container-player-wrapper">
          <div className="container-player-group">
            <div className="player-title-box">
              <h2>VIP</h2>
              <div className="tab-primary-box">
                <ul id="vip-tabMenu">
                  {tabs.map((tab) => (
                    <li key={tab.id}>
                      <a
                        href={`#${tab.id}`}
                        onClick={(e) => handleTabClick(e, tab.id)}
                        className={activeTab === tab.id ? "active" : ""}
                      >
                        {tab.label}
                      </a>
                    </li>
                  ))}
                </ul>
                <div className="slider-tab" style={{ width: "160px", left: `${sliderPosition}px` }}></div>
              </div>
            </div>

            <div className="main-tab-content">
              {activeTab === "vipInfo" && (
                <section id="vipInfo" className="tab-pane active">
                  <div className="vip-info-top-group">
                    <div className="vip-main-info bg-inner">
                      <div className="vip-bg-img"></div>
                      <div className="vip-level-info">
                        <img
                          src="https://www.jeetbuzz.com/images/v1/web/jb/vip/bdt/rank1.png"
                          alt="VIP Level Icon"
                          style={{ width: "3rem", height: "3rem", margin: "0 auto" }}
                        />
                        <div className="vip-name">
                          <label>VIP LEVEL</label>
                          <p id="vip-level">Normal</p>
                        </div>
                      </div>
                    </div>
                    <div className="vip-sub-info bg-inner">
                      <div className="vip-points">
                        <i></i>
                        <span id="vip-totalPoint">0</span>
                      </div>
                      <a className="btn-2nd-xs btn-more-vip-points" href="#">
                        View VIP Details
                      </a>
                    </div>
                  </div>
                  <div className="player-vip-box cash-card">
                    <div className="title">
                      <h2>Convert VP</h2>
                    </div>
                    <div className="cash-points">
                      <div className="cash-tips">
                        <p>
                          Minimum VP Required: <span id="vip-requirePoints">4000</span>
                        </p>
                      </div>
                      <div className="cash-cont">
                        <div className="left">
                          <div className="coin">
                            <video width="100%" height="100%" autoPlay muted loop playsInline poster="https://www.jeetbuzz.com/images/web/vip/coin-rotate-silver.png">
                              <source src="https://www.jeetbuzz.com/images/web/vip/coin-rotate-silver-alpha.mov" type="video/quicktime" />
                              <source src="https://www.jeetbuzz.com/images/web/vip/coin-rotate-silver-alpha.webm" type="video/webm" />
                            </video>
                          </div>
                          <div className="cash-detail">
                            <div className="cash-input">
                              <label>VP</label>
                              <input type="number" id="point" placeholder="0" />
                            </div>
                          </div>
                        </div>
                        <div className="convert-icon">&#8594;</div>
                        <div className="right">
                          <div className="coin">
                            <video width="100%" height="100%" autoPlay muted loop playsInline poster="https://www.jeetbuzz.com/images/web/vip/coin-rotate-gold-alpha.png">
                              <source src="https://www.jeetbuzz.com/images/web/vip/coin-rotate-gold-alpha.mov" type="video/quicktime" />
                              <source src="https://www.jeetbuzz.com/images/web/vip/coin-rotate-gold-alpha.webm" type="video/webm" />
                            </video>
                          </div>
                          <div className="cash-detail">
                            <div className="cash-input">
                            <label>Real Money</label>
                            <input type="number" id="money" placeholder="0" />
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div className="conversion">
                        <p>
                          VP Conversion Ratio: <span id="vip-convertRatio">400</span>
                        </p>
                      </div>
                    </div>
                    <button id="vip-confirm-convert" className="button default">
                      Convert to Real Money
                    </button>
                  </div>
                </section>
              )}

              {activeTab === "vipReceived" && (
                <section id="vipReceived" className="tab-pane active">
                <div className="table-container">
                  <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                    <div className="dataTables_length" id="DataTables_Table_0_length">
                      <label>
                        <select
                          onChange={() =>
                            setTimeout(() => {
                              const dataTable = document.querySelector("#vipReceived .table-box").DataTable;
                              if (!dataTable || !dataTable.fnPageChange) return;
                              dataTable.fnPageChange(0);
                            }, 10)
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        records per page
                      </label>
                    </div>
              
                    <div id="DataTables_Table_0_processing" className="dataTables_processing" style={{ display: "none" }}>
                      Processing...
                    </div>
              
                    <table
                      className="table-box display dataTable no-footer"
                      id="DataTables_Table_0"
                      role="grid"
                      aria-describedby="DataTables_Table_0_info"
                      style={{ width: "889px" }}
                    >
                      <thead>
                        <tr className="table-title" role="row">
                          <th className="column-num txt-center sorting_asc" style={{ width: "92px" }}>
                            No
                          </th>
                          <th className="column-s sorting_disabled" style={{ width: "167px" }}>
                            VIP Points (VP)
                          </th>
                          <th className="column-l sorting_disabled" style={{ width: "295px" }}>
                            Source
                          </th>
                          <th className="column-time sorting_disabled" style={{ width: "220px" }}>
                            Time <span name="timeZoneText">GMT+6</span>
                          </th>
                        </tr>
                      </thead>
              
                      <tbody>
                        <tr className="odd">
                          <td valign="top" colSpan="4" className="dataTables_empty">
                            <div className="no-info-txt">
                              <div className="no-info-img">
                                <img className="img-load" src="https://www.jeetbuzz.com/images/web/player/table/no-deposit.svg" alt="No data" />
                              </div>
                              <p>No Data</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
              
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                      Showing 0 to 0 of 0 entries
                    </div>
              
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate" style={{ display: "none" }}>
                      <a className="paginate_button previous disabled" aria-controls="DataTables_Table_0" data-dt-idx="0" tabIndex="-1" id="DataTables_Table_0_previous">
                        Previous
                      </a>
                      <span></span>
                      <a className="paginate_button next disabled" aria-controls="DataTables_Table_0" data-dt-idx="1" tabIndex="-1" id="DataTables_Table_0_next">
                        Next
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              
              )}

              {activeTab === "vipUsed" && (
               <section id="vipUsed" className="tab-pane active">
               <div className="table-container">
                 <div id="DataTables_Table_1_wrapper" className="dataTables_wrapper no-footer">
                   
                   <div className="dataTables_length" id="DataTables_Table_1_length">
                     <label>
                       <select
                         onChange={() => {
                           // Add pagination functionality here if needed
                         }}
                       >
                         <option value="10">10</option>
                         <option value="25">25</option>
                         <option value="50">50</option>
                         <option value="100">100</option>
                       </select>{" "}
                       records per page
                     </label>
                   </div>
             
                   <div id="DataTables_Table_1_processing" className="dataTables_processing" style={{ display: "none" }}>
                     Processing...
                   </div>
             
                   <table
                     className="table-box display dataTable no-footer"
                     id="DataTables_Table_1"
                     role="grid"
                     aria-describedby="DataTables_Table_1_info"
                     style={{ width: "889px" }}
                   >
                     <thead>
                       <tr className="table-title" role="row">
                         <th className="column-num txt-center" style={{ width: "92px" }}>
                           No
                         </th>
                         <th className="column-s" style={{ width: "167px" }}>
                           VIP Points (VP)
                         </th>
                         <th className="column-l" style={{ width: "295px" }}>
                           Amount
                         </th>
                         <th className="column-time" style={{ width: "220px" }}>
                           Time <span name="timeZoneText">GMT+6</span>
                         </th>
                       </tr>
                     </thead>
                     <tbody>
                       <tr className="odd">
                         <td valign="top" colSpan="4" className="dataTables_empty">
                           <div className="no-info-txt">
                             <div className="no-info-img">
                             <img className="img-load" src="https://www.jeetbuzz.com/images/web/player/table/no-deposit.svg" alt="No data" />
                             </div>
                             <p>No Data</p>
                           </div>
                         </td>
                       </tr>
                     </tbody>
                   </table>
             
                   <div className="dataTables_info" id="DataTables_Table_1_info" role="status" aria-live="polite">
                     Showing 0 to 0 of 0 entries
                   </div>
             
                   <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_1_paginate" style={{ display: "none" }}>
                     <a
                       className="paginate_button previous disabled"
                       aria-controls="DataTables_Table_1"
                       tabIndex="-1"
                       id="DataTables_Table_1_previous"
                     >
                       Previous
                     </a>
                     <span></span>
                     <a
                       className="paginate_button next disabled"
                       aria-controls="DataTables_Table_1"
                       tabIndex="-1"
                       id="DataTables_Table_1_next"
                     >
                       Next
                     </a>
                   </div>
                   
                 </div>
               </div>
             </section>
             
              )}

              {activeTab === "vipHistory" && (
               <section id="vipHistory" className="tab-pane active">
               <div className="acquired-content" id="experienceHistory">
                 <div className="history-content" style={{ display: "block" }}>
                   <div className="vip-year">2024</div>
                   <div className="vip-history-list">
                     <ul className="form-vip-history" style={{ display: "block" }}>
                       <li className="vip-month">Oct</li>
                       <li className="vip-level">
                         <img
                           src="https://www.jeetbuzz.com/images/v1/web/jb/vip/bdt/rank1.png"
                           alt=""
                           style={{
                             display: "block",
                             position: "absolute",
                             top: "1.25rem",
                             right: "1.25rem",
                             width: "2.8125rem",
                             height: "2.8125rem",
                             borderRadius: "50%",
                           }}
                         />
                         <div className="text">VIP LEVEL</div>
                         <div className="level">Normal</div>
                       </li>
                       <li className="vip-acquired">
                         <div className="text">Experience Acquired</div>
                         <div className="acquired">0</div>
                       </li>
                     </ul>
                   </div>
                 </div>
               </div>
             </section>
             
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default VIP;

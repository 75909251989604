import React from "react";

const ResetPassword = () => {
  return (
    <div className="main-content-wrap">
      <div className="tips-info verify-tips" style={{ display: "none" }}>
        <div className="tips-title-box">
          <h6>
            <p>Please complete the verification below before you proceed with the withdrawal request.</p>
          </h6>
          <div className="close-tips"></div>
        </div>
        <ol>
          <li className="info-personal">
            <label>Personal Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Birthday</li>
              </div>
            </ul>
          </li>

          <li className="info-contact">
            <label>Contact Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Phone Number</li>
              </div>
            </ul>
          </li>
        </ol>
      </div>

      <div className="top-overview-wrap">
        <ul>
          <li>
            <div className="left-overview-area">
              <label>Main Wallet</label>
              <div className="total-money totalBalanceWallet">
                <span className="balance" style={{ paddingRight: "7px" }}>৳</span>
                <p className="balance">0</p>
              </div>
            </div>
          </li>
          <li style={{ display: "none" }}>
            <div id="topOverviewTotalBonusWallet" className="left-overview-area">
              <label>Bonus Wallet</label>
              <div className="total-money totalBonusWallet">
                <span className="balance" style={{ paddingRight: "7px" }}>৳</span>
                <p>0</p>
              </div>
            </div>
          </li>
          <li>
            <div className="left-overview-area danger-state">
              <label>Security Level</label>
              <p id="contactVerifyStatus">Unverified</p>
            </div>
            <div className="right-overview-area">
              <a id="verify-mail-link" className="verify-mail status-verify-no"></a>
              <a id="verify-phone-link" className="verify-phone status-verify-no"></a>
            </div>
          </li>
        </ul>
      </div>

      <div id="mainContentBody" className="replaceable-block">
        <style>
          {`
            label.error {
              padding-top: 0.1875rem;
              color: #e93d3d;
              line-height: 1;
            }
          `}
        </style>

        <div className="container-player-group reset-password-wrap">
          <form name="changePasswordForm" id="changePasswordForm" noValidate>
            <div className="player-title-box">
              <h2>Reset password</h2>
            </div>

            <div className="tips-group password-tips">
              <p>Password requirements:</p>
              <ol>
                <li className="invalid" id="password-tips-length">must be 6-20 characters</li>
                <li className="invalid" id="password-tips-upperLetter">must contain 1 uppercase alphabet(A-Z) at least</li>
                <li className="invalid" id="password-tips-lowerLetter">must contain 1 lowercase alphabet(a-z) at least</li>
                <li className="invalid" id="password-tips-number">must contain 1 number(0-9) at least</li>
                <li className="invalid" id="password-tips-special-characters">allow special characters(@$!%*#)</li>
              </ol>
            </div>

            <div className="input-box">
              <div className="input-box-50">
                <label htmlFor="password">Current password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  minLength="6"
                  maxLength="20"
                  required
                  aria-required="true"
                  style={{ display: "block" }}
                />
              </div>
            </div>

            <div className="input-box">
              <div className="input-box-50">
                <label htmlFor="newPassword">New password</label>
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  minLength="6"
                  maxLength="20"
                  required
                  aria-required="true"
                  style={{ display: "block" }}
                />
              </div>

              <div className="input-box-50">
                <label htmlFor="confirmPassword">Confirm new password</label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  minLength="6"
                  maxLength="20"
                  required
                  aria-required="true"
                  style={{ display: "block" }}
                />
              </div>
            </div>

            <button className="btn-default submit">Confirm</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

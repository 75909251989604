import React from 'react';

const Announcement = () => {
    return (
        <mcd-marquee>
            <div className="announcement-row">
                <span
                    className="item-icon"
                    style={{
                        maskImage: `url('https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/index-announcement-icon.svg?v=1729676340443')`,
                    }}
                ></span>
                <div className="marquee">
                    <ul>
                        {/* You can add your announcements here as needed */}
                    </ul>
                </div>
            </div>
        </mcd-marquee>
    );
};

export default Announcement;

import React from "react";

const PlayerSideNav = ({ setActiveTab, activeTab }) => {
  return (
    <div className="player-sidenav-box">
      <nav className="player-sidenav">
        {/* Member Info Section */}
        <div className="member-info">
          <img
            src="https://www.jeetbuzz.com/images/v1/web/jb/vip/bdt/rank1.png"
            alt=""
            style={{ width: "3rem", height: "3rem", margin: "0 auto" }}
          />
          <div className="pre-load">
            <div
              className="photo bg-load data-image"
              style={{
                backgroundImage: "url('https://www.jeetbuzz.com/images/web/icon-set/player/sidenav/member-photo.svg')",
              }}
            ></div>
          </div>
          <div className="vip-level-icon pre-load">
            <i className="bg-load"></i>
            <p id="navUserName">emonhossaincse</p>
          </div>
          <div id="side-vip-points" className="vip-points">
            <i></i>
            <span>0</span>
          </div>
          <div className="progress-group">
            <div className="progress-inner">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "0%" }}
                ></div>
              </div>
              <div className="level">
                <span>Normal</span>
                <span>Elite I</span>
              </div>
            </div>
          </div>
        </div>

        {/* Money Management Section */}
        <div className="sidenav-area sidenav-money">
          <ul>
            <li className={`link-deposit ${activeTab === "deposit" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("deposit")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load" data-preload="https://www.jeetbuzz.com/images/web/player/sidenav/deposit.svg"></div>
                </div>
                <p>Deposit</p>
              </a>
            </li>
            <li className={`link-withdrawal ${activeTab === "withdrawal" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("withdrawal")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load" data-preload="https://www.jeetbuzz.com/images/web/player/sidenav/withdrawal.svg"></div>
                </div>
                <p>Withdrawal</p>
              </a>
            </li>
          </ul>
        </div>

        {/* Funds Section */}
        <div className="sidenav-area">
          <strong>Funds</strong>
          <ul>
            <li className={`link-bet-record ${activeTab === "bettingRecords" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("bettingRecords")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load"></div>
                </div>
                <p>Betting Records</p>
              </a>
            </li>
            <li className={`link-my-wallet ${activeTab === "wallet" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("wallet")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load"></div>
                </div>
                <p>My Wallet</p>
              </a>
            </li>
            <li className={`link-turnover ${activeTab === "turnover" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("turnover")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load"></div>
                </div>
                <p>Turnover</p>
              </a>
            </li>
            <li className={`link-vip ${activeTab === "vip" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("vip")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load"></div>
                </div>
                <p>VIP</p>
              </a>
            </li>
            <li className={`link-transaction-record ${activeTab === "transactionRecords" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("transactionRecords")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load"></div>
                </div>
                <p>Transaction Records</p>
              </a>
            </li>
          </ul>
        </div>

        {/* Profile Section */}
        <div className="sidenav-area">
          <strong>Profile</strong>
          <ul>
            <li className={`link-profile ${activeTab === "profile" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("profile")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load"></div>
                </div>
                <p>Personal Info</p>
              </a>
            </li>
            <li className={`link-password ${activeTab === "resetPassword" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("resetPassword")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load"></div>
                </div>
                <p>Reset Password</p>
              </a>
            </li>
            <li className={`link-mail ${activeTab === "inbox" ? "active" : ""}`}>
              <a onClick={() => setActiveTab("inbox")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load"></div>
                </div>
                <p>Inbox</p>
                <span>1</span>
              </a>
            </li>
            <li className={`link-friend ${activeTab === "referralProgram" ? "active" : ""}`} id="referralFeature">
              <a onClick={() => setActiveTab("referralProgram")}>
                <div className="sidenav-icon pre-load">
                  <div className="bg-load" data-preload="https://www.jeetbuzz.com/images/player/sidenav/friend.svg"></div>
                </div>
                <p>Referral Program</p>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default PlayerSideNav;

import React from "react";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";

function ResetPassword() {
    return (
        <div className="main-router-wrapper">
            <header id="header" className="login player">
                <div className="header-left-btn-group">
                   <UniversalBack/>
                    
                </div>
                <div className="header-title">Reset password</div>
                <div
                    className="logo"
                    tabIndex={0}
                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png?v=1729676340443")' }}
                />
                <div className="header-right-btn-group">
                    <a name="helpCenter" className="service-btn">
                        <span
                            className="item-icon"
                            style={{
                                maskImage:
                                    'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/header-service-icon.svg?v=1729676340443")',
                            }}
                        />
                        <p>Help</p>
                    </a>
                </div>
            </header>

            <div className="wrap">
                <div className="content member-content">
                    <div className="member-box radius">
                        <form noValidate className="ng-untouched ng-pristine ng-invalid">
                            {/* Current Password */}
                            <div className="inputbox password">
                                <div className="eyes" />
                                <label style={{ display: 'block' }}>Current password</label>
                                <input className="input" type="password" placeholder="Current password" />
                                <input className="clear" />
                            </div>

                            {/* New Password */}
                            <div className="inputbox password">
                                <div className="eyes" />
                                <label style={{ display: 'block' }}>New password</label>
                                <input className="input" type="password" placeholder="New password" />
                                <input className="clear" />
                            </div>

                            {/* Confirm New Password */}
                            <div className="inputbox password">
                                <div className="eyes" />
                                <label style={{ display: 'block' }}>Confirm new password</label>
                                <input className="input" type="password" placeholder="Confirm new password" />
                                <input className="clear" />
                            </div>

                            {/* Password Requirements */}
                            <div className="tips-info">
                                <div className="title-box">
                                    <h5>
                                        <span>Password requirements</span>
                                    </h5>
                                </div>
                                <ol>
                                    <li>Must be between 6 to 20 characters.</li>
                                    <li>Must contain 1 uppercase alphabet(A-Z) at least</li>
                                    <li>Must contain 1 lowercase alphabet(a-z) at least</li>
                                    <li>Must contain at least 1 number (0-9).</li>
                                    <li>Must contain special characters (@$%¨!%*#).</li>
                                </ol>
                            </div>

                            {/* Confirm Button */}
                            <div className="button submit">
                                <a>Confirm</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Toolbar />
        </div>
    );
}

export default ResetPassword;

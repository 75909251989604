import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const EventList = ({ selectedSport }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingInPlay, setLoadingInPlay] = useState(false);
  const [inPlayStatuses, setInPlayStatuses] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      let apiUrl = "https://six6.site/api/sports-events";
      if (selectedSport === "cricket") {
        apiUrl = "https://six6.site/api/sports-events/cricket";
      } else if (selectedSport === "soccer") {
        apiUrl = "https://six6.site/api/sports-events/soccer";
      } else if (selectedSport === "tennis") {
        apiUrl = "https://six6.site/api/sports-events/tennis";
      }

      try {
        const response = await axios.get(apiUrl);
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [selectedSport]);

  useEffect(() => {
    const fetchInPlayStatuses = async () => {
      if (events.length === 0) return;

      setLoadingInPlay(true);
      const newStatuses = {};
      await Promise.all(
        events.map(async (event) => {
          try {
            const response = await axios.get(
              `https://six6.site/api/match-odds/${event.MarketId}`
            );
            newStatuses[event.MarketId] = response.data[0]?.inplay || false;
          } catch (error) {
            console.error(
              `Error fetching in-play status for MarketId ${event.MarketId}:`,
              error
            );
            newStatuses[event.MarketId] = false;
          }
        })
      );
      setInPlayStatuses(newStatuses);
      setLoadingInPlay(false);
    };

    fetchInPlayStatuses();
  }, [events]);

  const handleEventClick = (marketId, eventId) => {
    navigate("/odds-view", {
      state: {
        marketId,
        eventId,
        selectedSport,
        previousPath: location.pathname,
      },
    });
  };

  const formatEventTime = (startTime) => {
    const eventDate = new Date(startTime);
    return eventDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  return (
    <div className="wrap-highlight_list">
      <h3>Highlights</h3>
      <ul id="highlightList" className="highlight-list">
        {loading ? (
          <div className="loading-overlay">
            <div className="loading-wrap" style={{ display: "flex" }}>
              <div className="loading">
                <div />
                <div />
              </div>
              <p>Loading events...</p>
            </div>
          </div>
        ) : loadingInPlay ? (
          <div className="loading-overlay">
            <div className="loading-wrap" style={{ display: "flex" }}>
              <div className="loading">
                <div />
                <div />
              </div>
              <p>Loading in-play statuses...</p>
            </div>
          </div>
        ) : (
          (() => {
            const inPlayEvents = events.filter(
              (event) => inPlayStatuses[event.MarketId]
            );
            const upcomingEvents = events
              .filter((event) => !inPlayStatuses[event.MarketId])
              .sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime));

            const sortedEvents = [...inPlayEvents, ...upcomingEvents];

            return sortedEvents.length > 0 ? (
              sortedEvents.map((event) => (
                <li
                  onClick={() => handleEventClick(event.MarketId, event.EventId)}
                  key={event.MarketId}
                  className={`${
                    inPlayStatuses[event.MarketId] ? "inplay-on" : "inplay-off"
                  }`}
                >
                  <span className="btn-h2h" id="headToHeadBtn" style={{ display: "none" }}>
                    <span id="h2h">H2H</span>
                  </span>
                  <a
                    onClick={() => handleEventClick(event.MarketId, event.EventId)}
                    className="event-link"
                  >
                    <dl>
                      <dt>
                        {event.Fancy && (
                          <span
                            id="fancyBetIcon"
                            className="game-fancy in-play"
                            style={{ display: "flex" }}
                          >
                            <pre>in-play</pre> Fancy
                          </span>
                        )}
                        {event.Bookmaker && (
                          <span
                            id="bookMakerIcon"
                            className="game-bookmaker in-play"
                            style={{ display: "flex" }}
                          >
                            <pre>in-play</pre> BookMaker
                          </span>
                        )}
                        <span className="time">
                          {inPlayStatuses[event.MarketId]
                            ? "In-Play"
                            : formatEventTime(event.StartTime)}
                        </span>
                      </dt>
                      <dd id="eventName">{event.Event}</dd>
                    </dl>
                  </a>
                  <a id="multiMarketPin" className="pin-off" href="#" />
                </li>
              ))
            ) : (
              <p>No events available.</p>
            );
          })()
        )}
      </ul>
    </div>
  );
};

export default EventList;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CasinoSlider = () => {
  const sliderSettings = {
    dots: true, // Enables react-slick's built-in dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const banners = [
    {
      id: "40490",
      image: "https://img.j189eb.com/upload/announcement/image_53654.jpg",
      title: "Jeetbuzz Casino",
    },
  ];

  return (
    <div id="gamePage_main_banner" className="main-banner-wrap slot" title="casino banner">
      <div className="main-slot-banner">
        <Slider {...sliderSettings} className="slides slick-initialized slick-slider slick-dotted" id="gamePage_promotion_container">
          {banners.map((banner, index) => (
            <div key={index} className="slick-list draggable">
              <div className="slick-track" style={{ opacity: 1, width: "1355px", transform: "translate3d(0px, 0px, 0px)" }}>
                <li
                  id={`slick-slide${index}`}
                  className="banner data-image slick-slide slick-current slick-active"
                  data-image={banner.image}
                  style={{
                    backgroundImage: `url(${banner.image})`,
                    width: "1355px",
                  }}
                  title={banner.title}
                  data-slick-index={index}
                  aria-hidden="false"
                  tabIndex="0"
                  role="tabpanel"
                  aria-describedby={`slick-slide-control${index}`}
                >
                  <a data-pk={banner.id} tabIndex="0"></a>
                </li>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CasinoSlider;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPaperclip, faPaperPlane, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";

function ChatBox() {
    const { chatId } = useParams();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        const fetchMessages = async () => {
            const token = localStorage.getItem("token");
            try {
                const response = await fetch(`https://six6.site/api/chats/${chatId}/messages`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.ok) {
                    const data = await response.json();
                    setMessages(data.messages || []);
                } else {
                    console.error("Failed to fetch messages.");
                }
            } catch (error) {
                console.error("Error fetching messages:", error);
            }
        };

        fetchMessages();
    }, [chatId]);

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        const token = localStorage.getItem("token");
        const senderId = localStorage.getItem("remote_id");

        try {
            const response = await fetch(`https://six6.site/api/messages`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    chat_id: chatId,
                    sender_id: '6',
                    content: newMessage,
                }),
            });

            if (response.ok) {
                const newMsg = await response.json();
                setMessages((prev) => [...prev, newMsg.data]);
                setNewMessage("");
            } else {
                console.error("Failed to send message.");
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    return (
        <div className="chat-box">
            <div className="chat-box-header">
                <Link to={"/chat"}>
                    <FontAwesomeIcon className="icon" icon={faChevronLeft} />
                </Link>
                <h3>Chat</h3>
                <FontAwesomeIcon className="icon" icon={faXmark} />
            </div>
            <div className="chat-box-body">
                {messages.map((msg, idx) => (
                    <div key={idx} className={`chat-box-msg ${msg.sender_id === chatId ? "left-box" : "right-box"}`}>
                        {msg.content}
                    </div>
                ))}
                {isTyping && <div className="typing-indicator">Typing...</div>}
            </div>
            <div className="chat-box-footer">
                <input
                    type="text"
                    placeholder="Type a message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onFocus={() => setIsTyping(true)}
                    onBlur={() => setIsTyping(false)}
                />
                <FontAwesomeIcon className="icon" icon={faPaperclip} />
                <button onClick={handleSendMessage}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
        </div>
    );
}

export default ChatBox;

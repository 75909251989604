import React, { useState } from "react";

const TransactionRecords = () => {
  const [txnType, setTxnType] = useState("0");
  const [txnStatus, setTxnStatus] = useState("0");
  const [dateOption, setDateOption] = useState("0");

  const handleFilterSubmit = () => {
    // Placeholder for filtering function
    console.log("Filter applied with:", {
      txnType,
      txnStatus,
      dateOption,
    });
  };

  return (
    <div className="main-content-wrap">
      {/* Verification Message */}
      <div className="tips-info verify-tips">
        <div className="tips-title-box">
          <h6>
            <p>Please complete the verification below before you proceed with the withdrawal request.</p>
          </h6>
          <div className="close-tips"></div>
        </div>
        <ol>
          <li className="info-personal">
            <label>Personal Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Birthday</li>
              </div>
            </ul>
          </li>
          <li className="info-contact">
            <label>Contact Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Phone Number</li>
              </div>
            </ul>
          </li>
        </ol>
      </div>

      {/* Transaction Filters */}
      <div className="container-player-group">
        <div className="player-title-box">
          <h2>Transaction Records</h2>
        </div>
        <div className="main-table-info active">
          <div className="filter-box">
            <div className="filter-group">
              <label>Type</label>
              <ul className="choose-box">
                <li>
                  <input
                    type="radio"
                    name="txnType"
                    value="0"
                    checked={txnType === "0"}
                    onChange={() => setTxnType("0")}
                  />
                  <label>Deposit</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="txnType"
                    value="1"
                    checked={txnType === "1"}
                    onChange={() => setTxnType("1")}
                  />
                  <label>Withdrawal</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="txnType"
                    value="2"
                    checked={txnType === "2"}
                    onChange={() => setTxnType("2")}
                  />
                  <label>Adjustment</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="txnType"
                    value="-1"
                    checked={txnType === "-1"}
                    onChange={() => setTxnType("-1")}
                  />
                  <label>All</label>
                </li>
              </ul>
            </div>

            <div className="filter-group">
              <label>Status</label>
              <ul className="choose-box">
                <li>
                  <input
                    type="radio"
                    name="txnStatus"
                    value="0"
                    checked={txnStatus === "0"}
                    onChange={() => setTxnStatus("0")}
                  />
                  <label>Processing</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="txnStatus"
                    value="1"
                    checked={txnStatus === "1"}
                    onChange={() => setTxnStatus("1")}
                  />
                  <label>Success</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="txnStatus"
                    value="2"
                    checked={txnStatus === "2"}
                    onChange={() => setTxnStatus("2")}
                  />
                  <label>Fail</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="txnStatus"
                    value="-1"
                    checked={txnStatus === "-1"}
                    onChange={() => setTxnStatus("-1")}
                  />
                  <label>All</label>
                </li>
              </ul>
            </div>

            <div className="filter-group">
              <label>Date</label>
              <ul className="choose-box">
                <li>
                  <input
                    type="radio"
                    name="dateOption"
                    value="0"
                    checked={dateOption === "0"}
                    onChange={() => setDateOption("0")}
                  />
                  <label>Today</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="dateOption"
                    value="1"
                    checked={dateOption === "1"}
                    onChange={() => setDateOption("1")}
                  />
                  <label>Yesterday</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="dateOption"
                    value="7"
                    checked={dateOption === "7"}
                    onChange={() => setDateOption("7")}
                  />
                  <label>Last 7 days</label>
                </li>
              </ul>
            </div>

            <div className="filter-btn-box">
              <button className="btn-5th-xs query-txn-report" onClick={handleFilterSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Transaction Records Table */}
      <div className="table-change-wrap">
  <div className="table-change-inner" style={{ left: '0px' }}>
    {/* Container with data */}
    <div className="container-player-group no-info">
      <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
        <div className="dataTables_length" id="DataTables_Table_0_length">
          <label>
            <select 
             
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> 
            records per page
          </label>
        </div>
        <div id="DataTables_Table_0_processing" className="dataTables_processing" style={{ display: 'none' }}>Processing...</div>
        <table className="table-box dataTable no-footer go-to-sub-table" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" style={{ width: '888px' }}>
          <thead>
            <tr className="table-title" role="row">
              <th className="column-s sorting_disabled" style={{ width: '16px' }} rowSpan="1" colSpan="1" aria-label="&nbsp;">&nbsp;</th>
              <th className="column-s sorting_disabled" rowSpan="1" colSpan="1" aria-label="Type" style={{ width: '131px' }}>
                <ui-translate key="fe.text.report.txnType" lang="en">Type</ui-translate>
              </th>
              <th className="column-s sorting_disabled" rowSpan="1" colSpan="1" aria-label="NO" style={{ width: '131px' }}>
                <ui-translate key="ui.text.report.txnNo" lang="en">NO</ui-translate>
              </th>
              <th className="column-s sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Amount: activate to sort column ascending" style={{ width: '131px' }}>
                <ui-translate key="fe.text.amount" lang="en">Amount</ui-translate>
              </th>
              <th className="column-time sorting_desc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Txn Date: activate to sort column ascending" style={{ width: '175px' }} aria-sort="descending">
                <ui-translate key="fe.text.report.txnDate" lang="en">Txn Date</ui-translate>
              </th>
              <th className="column-s sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Status: activate to sort column ascending" style={{ width: '131px' }}>
                <ui-translate key="fe.text.status" lang="en">Status</ui-translate>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr role="row" className="odd">
              <td>1</td>
              <td>Deposit Payment Gateway</td>
              <td>D603501845</td>
              <td className="txt-num">10,000</td>
              <td className="column-time sorting_1">2024/10/28 19:43:38 (GMT+6)</td>
              <td className="state-rejected"><span>Fail</span></td>
            </tr>
            <tr role="row" className="even">
              <td>2</td>
              <td>Deposit Payment Gateway</td>
              <td>D602848505</td>
              <td className="txt-num">10,000</td>
              <td className="column-time sorting_1">2024/10/28 01:46:26 (GMT+6)</td>
              <td className="state-approved"><span>Success</span></td>
            </tr>
            <tr role="row" className="odd">
              <td>3</td>
              <td>Deposit Payment Gateway</td>
              <td>D602844666</td>
              <td className="txt-num">6,000</td>
              <td className="column-time sorting_1">2024/10/28 01:38:41 (GMT+6)</td>
              <td className="state-approved"><span>Success</span></td>
            </tr>
            <tr role="row" className="even">
              <td>4</td>
              <td>Deposit Payment Gateway</td>
              <td>D601333018</td>
              <td className="txt-num">4,900</td>
              <td className="column-time sorting_1">2024/10/26 17:50:21 (GMT+6)</td>
              <td className="state-approved"><span>Success</span></td>
            </tr>
            <tr role="row" className="odd">
              <td>5</td>
              <td>Deposit Payment Gateway</td>
              <td>D597670110</td>
              <td className="txt-num">2,500</td>
              <td className="column-time sorting_1">2024/10/23 03:10:47 (GMT+6)</td>
              <td className="state-approved"><span>Success</span></td>
            </tr>
          </tbody>
        </table>
        <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 5 of 5 entries</div>
        <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate" style={{ display: 'block' }}>
          <a className="paginate_button previous disabled" aria-controls="DataTables_Table_0" data-dt-idx="0" tabIndex="-1" id="DataTables_Table_0_previous">Previous</a>
          <span>
            <a className="paginate_button current" aria-controls="DataTables_Table_0" data-dt-idx="1" tabIndex="0">1</a>
          </span>
          <a className="paginate_button next disabled" aria-controls="DataTables_Table_0" data-dt-idx="2" tabIndex="-1" id="DataTables_Table_0_next">Next</a>
        </div>
      </div>
    </div>

    {/* Transaction Detail Modal */}
    <div id="txnDetailModal" className="container-player-group hide">
      <div id="txn-bank-name" className="main-table-box">
        <a className="btn-return">
          <ui-translate key="fe.text.go_back" lang="en">back</ui-translate>
        </a>
        <div className="bank-name-block">
          <img src="" alt="" />
          <span>-</span>
        </div>
      </div>
      <div className="details-box">
        <div id="txn-progress" className="transaction-details-wrap timeline">
          <div className="title">
            <h3>
              <ui-translate key="fe.msg.dialog.transaction.progress" lang="en">Transaction Progress</ui-translate>
            </h3>
          </div>
          <div id="timeline" className="timeline-wrap">
            {/* Timeline Content */}
          </div>
        </div>
        <div id="txn-detail" className="transaction-details-wrap details">
          <div className="title">
            <h3>
              <ui-translate key="fe.msg.dialog.transaction.details" lang="en">Transaction Record Details</ui-translate>
            </h3>
            <i><span></span></i>
          </div>
          <div id="details-content" className="details-wrap">
            {/* Details Content */}
          </div>
          <button id="txn-revert" className="btn-default">
            <ui-translate key="fe.text.revert" lang="en">Revert</ui-translate>
          </button>
          <div className="tips" id="txn-tips">
            <ui-translate key="fe.text.reSubmitRemind" lang="en">You can edit after {0} minutes.</ui-translate>
          </div>
          <button id="txn-reSubmit" className="btn-default">
            <div className="countdown btn-gray">
              <div className="timer"></div>
              <span></span>
            </div>
            <ui-translate key="fe.text.reSubmit" lang="en">Edit and resubmit</ui-translate>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>



    </div>
  );
};

export default TransactionRecords;

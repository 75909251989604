import React from 'react';

const ScrollBanner = () => {
    return (
        <mcd-scroll-banner>
            <div className="recommend scroll-banner">
                <div className="recommend-title">
                    <h2>Favourites</h2>
                </div>
                <div className="recommend-bg">
                    <div className="recommend-main">
                        <div className="recommend-card">
                            <a>
                                <img 
                                    alt="image_181470" 
                                    src="https://img.j189eb.com/upload/announcement/image_181470.jpg" 
                                    loading="lazy" 
                                />
                            </a>
                        </div>
                        <div className="recommend-card">
                            <a>
                                <img 
                                    alt="image_181641" 
                                    src="https://img.j189eb.com/upload/announcement/image_181641.jpg" 
                                    loading="lazy" 
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </mcd-scroll-banner>
    );
};

export default ScrollBanner;

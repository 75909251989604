import React from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import PromotionBanner from "../../banner/PromotionBanner";
import PromotionNav from "../../navigation/desktop/PromotionNav";
import PromotionList from "../../common/PromotionList";

function Promotion(){

    return(
        <>
         <Header/>
        <div className="content" style={{ paddingTop: "145px" }}>
           <PromotionBanner/>
           <PromotionNav/>
           <PromotionList/>
        </div>
        <Footer/>

        </>
    );

}
export default Promotion;
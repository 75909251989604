import React, { useEffect, useState } from "react";
import SportsHeader from "./SportsHeader";
import EventList from "./EventList";
import EXHeader from "./EXHeader";
import EXNav from "./EXNav";

function EXHome(){
    const [selectedSport, setSelectedSport] = useState('cricket');

    
    return(
       
  <div data-role="page" tabIndex={0} className="ui-page ui-page-theme-a ui-page-active" style={{}}>
    
      <EXHeader/>

    <div id="page" role="page">
      <div className="mian-wrap">
      <SportsHeader onSportSelect={setSelectedSport} selectedSport={selectedSport} />


<EventList selectedSport={selectedSport} />
      <EXNav/>
      </div>
    </div>
  </div>
  


    );

}
export default EXHome;
import React, { useState } from 'react';

const RewardHistoryBox = () => {
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]); // Placeholder for actual data

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(0);
  };

  const renderTableRows = () => {
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="dataTables_empty">
            <div className="no-info-txt">
              <div className="no-info-img">
                <img className="img-load" src="https://www.jeetbuzz.com/images/web/player/table/no-deposit.svg" alt="No data" />
              </div>
              <p>No Data</p>
            </div>
          </td>
        </tr>
      );
    }
    return data.slice(currentPage * recordsPerPage, (currentPage + 1) * recordsPerPage).map((row, index) => (
      <tr key={index}>
        <td>{row.date}</td>
        <td>{row.turnover}</td>
        <td>{row.cashReward}</td>
        <td>{row.status}</td>
        <td>{row.details}</td>
      </tr>
    ));
  };

  return (
    <div className="reward-history-box">
      <div className="dataTables_wrapper no-footer">
        <div className="dataTables_length">
          <label>
            <select value={recordsPerPage} onChange={handleRecordsPerPageChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            records per page
          </label>
        </div>

        <table className="table-box dataTable no-footer" role="grid" aria-describedby="DataTables_Table_6_info">
          <thead>
            <tr className="table-title" role="row">
              <th>Date</th>
              <th>Turnover</th>
              <th>Cash Reward</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>

        <div className="dataTables_info">
          Showing {currentPage * recordsPerPage + 1} to {(currentPage + 1) * recordsPerPage} of {data.length} entries
        </div>
        
       
      </div>
    </div>
  );
};

export default RewardHistoryBox;

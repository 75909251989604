import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PromotionBanner = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const banners = [
    {
      id: "37007",
      image: "https://img.j189eb.com/upload/announcement/image_42274.jpg",
      title: "Promotions",
    },
    // Add more banners here if needed
  ];

  return (
    <div id="promotion_banner_wrap" className="main-banner-wrap promotion">
      <div className="main-promotion-banner">
        <Slider {...sliderSettings} className="slides slick-initialized slick-slider slick-dotted" id="main_announcement_container">
          {banners.map((banner, index) => (
            <div key={index} className="banner data-image slick-slide" title={banner.title}>
              <a data-pk={banner.id} tabIndex="0">
                <img
                  src={banner.image}
                  alt={banner.title}
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "block",
                  }}
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PromotionBanner;

import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BettingRecords = () => {
  const [dateOption, setDateOption] = useState(0);

  const handleDateOptionChange = (value) => {
    setDateOption(value);
  };

  return (
    <div className="main-content-wrap">
      {/* Verification Message */}
      <div className="tips-info verify-tips">
        <div className="tips-title-box">
          <h6>
            <p>Please complete the verification below before you proceed with the withdrawal request.</p>
          </h6>
          <div className="close-tips"></div>
        </div>
        <ol>
          <li className="info-personal">
            <label>Personal Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Birthday</li>
              </div>
            </ul>
          </li>
          <li className="info-contact">
            <label>Contact Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Phone Number</li>
              </div>
            </ul>
          </li>
        </ol>
      </div>

      {/* Account Overview */}
      <div className="top-overview-wrap">
        <ul>
          <li>
            <div className="left-overview-area">
              <label>Main Wallet</label>
              <div className="total-money totalBalanceWallet">
                <span className="balance">৳</span>
                <p className="balance">0</p>
              </div>
            </div>
          </li>
          <li>
            <div className="left-overview-area danger-state">
              <label>Security Level</label>
              <p id="contactVerifyStatus">Unverified</p>
            </div>
          </li>
        </ul>
      </div>

      {/* Betting Records Section */}
      <div className="container-player-group">
        <div className="player-title-box">
          <h2>Betting Records</h2>
        </div>
        <div className="main-table-info">
          <div className="tab-secondary-box">
            <ul className="tabs">
              <li>
                <input type="radio" name="betRecord" id="settledTab" defaultChecked />
                <label htmlFor="settledTab">Settled</label>
              </li>
              <li data-pagetype="3.5">
                <input type="radio" name="betRecord" id="unSettledTab" />
                <label htmlFor="unSettledTab">Unsettled</label>
              </li>
            </ul>
          </div>

          <div className="filter-box">
            {/* Platform Filter */}
            <div id="filterVendor" className="filter-group" style={{ width: "fit-content" }}>
              <label>Platform</label>
              <ul id="vendorFilterContainer" className="choose-box">
                <li>
                  <input type="checkbox" name="platform" id="vendor-0" value="-1" defaultChecked />
                  <label htmlFor="vendor-0">All</label>
                </li>
                <li>
                  <input type="checkbox" name="platform" id="vendor-96" />
                  <label htmlFor="vendor-96">KA Gaming</label>
                </li>
                <li>
                  <input type="checkbox" name="platform" id="vendor-48" />
                  <label htmlFor="vendor-48">Sexy</label>
                </li>
                <li>
                  <input type="checkbox" name="platform" id="vendor-69" />
                  <label htmlFor="vendor-69">Red Tiger</label>
                </li>
                <li>
                  <input type="checkbox" name="platform" id="vendor-70" />
                  <label htmlFor="vendor-70">Spadegaming</label>
                </li>
              </ul>
            </div>

            {/* Game Type Filter */}
            <div id="filterGameType" className="filter-group" style={{ width: "fit-content" }}>
              <label>Game Type</label>
              <ul id="gameTypeFilterContainer" className="choose-box">
                <li>
                  <input type="checkbox" name="gameType" id="gameType-0" value="-1" defaultChecked />
                  <label htmlFor="gameType-0">All</label>
                </li>
                <li>
                  <input type="checkbox" name="gameType" id="gameType-1" />
                  <label htmlFor="gameType-1">Slots</label>
                </li>
                <li>
                  <input type="checkbox" name="gameType" id="gameType-2" />
                  <label htmlFor="gameType-2">Live Casino</label>
                </li>
                <li>
                  <input type="checkbox" name="gameType" id="gameType-4" />
                  <label htmlFor="gameType-4">Sports</label>
                </li>
                <li>
                  <input type="checkbox" name="gameType" id="gameType-8" />
                  <label htmlFor="gameType-8">Fishing</label>
                </li>
              </ul>
            </div>

            {/* Date Filter */}
            <div className="filter-group">
              <label>Date</label>
              <ul className="choose-box">
                <li>
                  <input
                    type="radio"
                    name="dateOption"
                    id="dateOption0"
                    value="0"
                    checked={dateOption === 0}
                    onChange={() => handleDateOptionChange(0)}
                  />
                  <label htmlFor="dateOption0">Today</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="dateOption"
                    id="dateOption1"
                    value="1"
                    checked={dateOption === 1}
                    onChange={() => handleDateOptionChange(1)}
                  />
                  <label htmlFor="dateOption1">Yesterday</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="dateOption"
                    id="dateOption7"
                    value="7"
                    checked={dateOption === 7}
                    onChange={() => handleDateOptionChange(7)}
                  />
                  <label htmlFor="dateOption7">Last 7 days</label>
                </li>
              </ul>
            </div>

            <div className="filter-btn-box">
              <button className="btn-5th-xs query-bet-report">Submit</button>
            </div>
          </div>
        </div>

        {/* Betting Records Table */}
        <div className="table-change-wrap" id="betReportTable">
          <div className="table-container">
            <table className="table-box">
              <thead>
                <tr>
                  <th>Platform</th>
                  <th>Game Type</th>
                  <th>Turnover</th>
                  <th>Profit/Loss</th>
                  <th>Settle Date</th>
                </tr>
              </thead>
              <tbody>
                {/* Placeholder data */}
                <tr>
                  <td colSpan="5" className="dataTables_empty">
                    <div className="no-info-txt">
                      <div className="no-info-img">
                        <img src="https://www.jeetbuzz.com/images/web/player/table/no-deposit.svg" alt="No Data" />
                      </div>
                      <p>No Data</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BettingRecords;

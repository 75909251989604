import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import WithdrawNotice from "../../notice/mobile/WithdrawNotice";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";

function MWithdrawal() {
    const [activeItem, setActiveItem] = useState(null); // Selected payment method
    const [activeAmount, setActiveAmount] = useState(''); // Selected deposit amount
    const [items, setItems] = useState([]); // Payment methods
    const [promotions, setPromotions] = useState([]); // Promotions data
    const [selectedPromotion, setSelectedPromotion] = useState(null); // Selected promotion
    const [isLoading, setIsLoading] = useState(true); // Loading state for API
  
    const amounts = ['2000', '3000', '5000', '10000', '15000', '20000', '25000', '1000', '500', '100']; // Predefined amounts
  
    // Fetch payment methods from API
    useEffect(() => {
      setIsLoading(true);
      fetch('https://six6.site/api/payment-methods')
        .then(response => response.json())
        .then(data => setItems(data))
        .catch(error => console.error('Error fetching payment methods:', error))
        .finally(() => setIsLoading(false));
    }, []);
  
   
  
    const handleItemClick = (id) => {
      setActiveItem(id); // Update selected payment method
    };
  
    const handleAmountClick = (amount) => {
      setActiveAmount(amount); // Update selected deposit amount
    };
  
   
  return (
    <div className="main-router-wrapper">
      <header id="header" className="login player">
        <div className="header-left-btn-group">
          <UniversalBack/>
        </div>
        <div className="header-title">Funds</div>
        <div className="logo" tabIndex={0} style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png")' }} />
      </header>

      <div className="wrap">
        <div className="content fixed-tab player-content">
          <div className="tab-btn-section tab-btn-wrap">
            <div className="tab-btn tab-btn-bar">
              <div className="line" style={{width: 'calc(50%)', transform: 'translate(100%, 0px)'}} />
              <div className="btn">
                <Link className="text-white" to="/deposit"><div className="text">Deposit</div></Link>
               
              </div>
              <div className="btn active">
                <Link className="text-white" to="/withdrawal"><div className="text">Withdrawal</div></Link>
                
              </div>
            </div>
          </div>
          

          <div className="tab-content disable">
            <div className="inner-wrap">
              <div className="inner-box deposit-wallet">
                <div className="player-deposit-wrap">
                  <div className="player-deposit-step1">
                   
                    <WithdrawNotice/>

                    {/* Payment Method */}
                    <div className="menu-box">
                      <div className="title">
                        <h2>Payment Method</h2>
                      </div>
                      <div className="select-group checkbox-style">
                        <ul className="col3">
                        {items.map((item) => (
                            item.status === 'active' && (
                        <li 
                        key={item.id}
                        onClick={() => handleItemClick(item.id)}
                        className="ng-star-inserted">
                            <input  type="radio" name="paymentMethod" id={`paymentMethod_${item.id}`} />
                            <label  htmlFor={`paymentMethod_${item.id}`}>
                                <div  className="bank ng-star-inserted">
                                <img  alt="rocket" src={`https://six6.site/storage/${item.icon}`} loading="lazy" />
                                </div>
                               
                                <span >{item.name}</span>
                               
                                
                               
                                <span  className="item-icon" style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/select-check.svg?v=1729676340443")'}} />
                            </label>
                            </li>
                            )
                        ))}
                         
                        </ul>
                      </div>
                    </div>

                    {/* Amount Selection */}
                    <div className="menu-box active">
                      <div className="title">
                        <h2>Amount <i>৳ 500.00 - ৳ 25,000.00</i></h2>
                      </div>
                      <div className="select-group style-add-amount">
                        <ul className="col4">
                        {amounts.map((amount) => (
                          <li
                          key={amount}
                          onClick={() => handleAmountClick(amount)}
                          >
                            <input type="radio" name="depositAmount" id={`depositAmount_${amount}`} />
                            <label htmlFor={`depositAmount_${amount}`}>
                                
                              <span>{amount}</span>
                            </label>
                          </li>
                           ))}
                         
                        </ul>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="amount">৳</label>
                        <div className="input-wrap">
                          <input type="text" name="amount" value={activeAmount} placeholder="0.00" readOnly />
                        </div>
                      </div>
                      <div  className="tips-info note  ng-star-inserted" style={{}}>
                        <h5  className="">
                            <i  className="tips-icon " style={{maskImage: 'url("/assets/images/icon-set/icon-tips-type02.svg")'}} />
                            <span  className="">
                           
                            Reminder : 1. Please double check the recipient's account details before proceeding. 2. DO NOT share your account with any one to avoid losing fund or money. 3.
                                                                            Please make sure your bank account holder name and Jeetbuzz registered name should match to prevent from withdrawal rejection.
                                                                        
                            </span>
                        </h5>
                        </div>

                    </div>

                    {/* Submit Button */}
                    <div className="member-content">
                      <div className="button">
                        <a>Submit</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toolbar/>
    </div>
  );
}

export default MWithdrawal;

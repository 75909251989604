import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProfileView = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const promotions = [
    {
      id: "72488",
      title: "৳7,777 Sports Bonus",
      image: "https://img.j189eb.com/upload/announcement/image_130732.png",
    },
    {
      id: "91864",
      title: "100% Bonus + Extra Free Spins",
      image: "https://img.j189eb.com/upload/announcement/image_173302.jpg",
    },
    {
      id: "27816",
      title: "50% Sport Refund",
      image: "https://img.j189eb.com/upload/announcement/image_118659.png",
    },
  ];

  return (
    <div className="main-content-wrap">
      {/* Verification Message */}
      <div className="tips-info verify-tips">
        <div className="tips-title-box">
          <h6>
            <p>Please complete the verification below before you proceed with the withdrawal request.</p>
          </h6>
          <div className="close-tips"></div>
        </div>
        <ol>
          <li className="info-personal">
            <label>Personal Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Birthday</li>
              </div>
            </ul>
          </li>
          <li className="info-contact">
            <label>Contact Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Phone Number</li>
              </div>
            </ul>
          </li>
        </ol>
      </div>

      {/* Account Overview */}
      <div className="top-overview-wrap">
        <ul>
          <li>
            <div className="left-overview-area">
              <label>Main Wallet</label>
              <div className="total-money totalBalanceWallet">
                <span className="balance">৳</span>
                <p className="balance">0</p>
              </div>
            </div>
          </li>
          <li style={{ display: "none" }}>
            <div id="topOverviewTotalBonusWallet" className="left-overview-area">
              <label>Bonus Wallet</label>
              <div className="total-money totalBonusWallet">
                <span className="balance">৳</span>
                <p>0</p>
              </div>
            </div>
          </li>
          <li>
            <div className="left-overview-area danger-state">
              <label>Security Level</label>
              <p id="contactVerifyStatus">Unverified</p>
            </div>
            <div className="right-overview-area">
              <a id="verify-mail-link" className="verify-mail status-verify-no"></a>
              <a id="verify-phone-link" className="verify-phone status-verify-no"></a>
            </div>
          </li>
        </ul>
      </div>

      {/* User Details */}
      <div className="container-player-group member-day-data">
        <ul className="col-4">
          <li>
            <label>Last Login Time</label>
            <p id="loginTime">2024/10/26 21:52:43</p>
          </li>
          <li>
            <label>Last Deposit Time</label>
            <p id="lastDepositTime">
              <a className="btn-player-secendary btn-5th-xs">Deposit now</a>
            </p>
          </li>
          <li>
            <label>Last Withdrawal Time</label>
            <p id="lastWithdrawalTime">No Data</p>
          </li>
          <li>
            <label>Date Registered</label>
            <p id="createTime">2024/10/26 21:51:34</p>
          </li>
        </ul>
      </div>

      {/* Promotion Slider */}
      <div id="profilePromotionContainer" className="container-player-group new-promotion-slider">
        <h5>New Promotions</h5>
        <div className="promotion-slide-box">
          <Slider {...sliderSettings}>
            {promotions.map((promotion, index) => (
              <div key={index}>
                <a data-id={`promotion-${promotion.id}`}>
                  <div
                    className="member-banner-slider data-image"
                    style={{ backgroundImage: `url(${promotion.image})` }}
                  ></div>
                  <p>{promotion.title}</p>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Personal Information */}
      <div className="personal-info">
        <ul className="personal-data">
          <li>
            <i
              className="data-image"
              style={{
                backgroundImage: "url('https://www.jeetbuzz.com/images/web/communication/white/icon-member.png')",
              }}
            ></i>
            <p id="userName">Emon Hossain</p>
          </li>
        </ul>
        <ul className="personal-data personal-phone-group" id="phoneNumbersGroup">
          <li>
            <i
              style={{
                backgroundImage: "url('https://www.jeetbuzz.com/images/web/communication/white/icon-phone.png')",
              }}
            ></i>
            <p id="showPhoneNumber1">1799872359</p>
            <span className="verify active">Verify</span>
          </li>
        </ul>
        <ul className="personal-data">
          <li id="email">
            <i
              className="data-image"
              style={{
                backgroundImage: "url('https://www.jeetbuzz.com/images/web/communication/white/icon-mail.png')",
              }}
            ></i>
            <p id="email2">emonhossaincse@gmail.com</p>
          </li>
        </ul>
        <ul className="personal-data">
          <li id="birthday" className="need-verify">
            <i
              className="data-image"
              style={{
                backgroundImage: "url('https://www.jeetbuzz.com/images/web/communication/white/icon-birthday.png')",
              }}
            ></i>
            <p>Edit Birthday</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileView;

import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./components/assets/css/LiveChat.css";
import DHome from './components/pages/desktop/Home';
import DCasino from './components/pages/desktop/Casino';
import Promotion from './components/pages/desktop/Promotion';
import Account from './components/pages/desktop/Account';
import Registion from './components/pages/desktop/Registion';
import Home from './components/pages/mobile/Home';
import Login from './components/pages/mobile/Login';
import SignUp from './components/pages/mobile/SignUp';
import MAccount from './components/pages/mobile/MAccount';
import Profile from './components/pages/mobile/Profile';
import MDeposit from './components/pages/mobile/MDeposit';
import MWithdrawal from './components/pages/mobile/MWithdrawal';
import BettingRecord from './components/pages/mobile/BettingRecord';
import TransactionRecord from './components/pages/mobile/TransactionRecord';
import TurnoverRecord from './components/pages/mobile/TurnoverRecord';
import RefrrelProgram from './components/pages/mobile/RefrrelProgram';
import ResetPassword from './components/pages/mobile/ResetPassword';
import MobileInbox from './components/pages/mobile/MobileInbox';
import VIPConvert from './components/pages/mobile/VIPConvert';
import Games from './components/pages/mobile/Games';
import GameRun from './components/pages/mobile/GameRun';
import MobilePromotion from './components/pages/mobile/MobilePromotion';
import ChatHome from './components/Chat/ChatHome';
import ChatHistory from './components/Chat/ChatHistory';
import ChatBox from './components/Chat/ChatBox';
import EXHome from './components/exchanges/EXHome';
import MatchOdds from './components/exchanges/MatchOdds';
import ExAccount from './components/exchanges/ExAccount';
import InPlay from './components/exchanges/InPlay';
import SplashGif from './splash/mobile.gif'; // Import splash GIF

// Utility function to check if the device is mobile
const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

function App() {
  const isDeviceMobile = isMobile();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); // Initialize based on token presence
  const [lastReloadTime, setLastReloadTime] = useState(Date.now()); // Track last reload time
  const location = useLocation();
  const [showSplash, setShowSplash] = useState(true); // State for splash screen

  useEffect(() => {
    // Hide the splash screen after 3 seconds
    const splashTimeout = setTimeout(() => setShowSplash(false), 1000);
    return () => clearTimeout(splashTimeout);
  }, []);

  const canReload = () => {
    const currentTime = Date.now();
    const reloadInterval = 5000; // 5 seconds in milliseconds
    if (currentTime - lastReloadTime < reloadInterval) {
      return false;
    }
    setLastReloadTime(currentTime);
    return true;
  };

  const fetchBalance = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch('https://six6.site/api/balance', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('balance', data.balance);
        } else if (response.status === 401) {
          handleLogout();
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    } else {
      console.warn('No token found in local storage.');
    }
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage data
    setIsLoggedIn(false);
    window.location.href = '/';
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalance();
      const interval = setInterval(fetchBalance, 1000);
      return () => clearInterval(interval);
    }
  }, [isLoggedIn]);

  // Import styles based on the device type and current route
  useEffect(() => {
    if (isDeviceMobile) {
      import('./components/assets/css/Mobile.css');
      import('./components/assets/css/flipclock.css');
    } else {
      import('./components/assets/css/main-ui.css');
      import('./components/assets/css/palyer-ui.css');
    }
  }, [isDeviceMobile]);

  useEffect(() => {
    if (
      location.pathname === '/exchange' ||
      location.pathname === '/odds-view' ||
      location.pathname === '/exchange-account' ||
      location.pathname === '/exchange-inplay'
    ) {
      import('./components/assets/css/theme_jb.css');
    }
  }, [location.pathname]);

  return (
    <div className={`App ${isDeviceMobile ? 'mobile' : 'desktop'}`}>
      {/* Splash Screen */}
      {showSplash ? (
        <div className="splash-screen">
          <img src={SplashGif} alt="Loading..." />
        </div>
      ) : (
        <Routes>
          {isDeviceMobile ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/account" element={<MAccount />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/deposit" element={<MDeposit />} />
              <Route path="/withdrawal" element={<MWithdrawal />} />
              <Route path="/betting-record" element={<BettingRecord />} />
              <Route path="/transaction-record" element={<TransactionRecord />} />
              <Route path="/turnover-record" element={<TurnoverRecord />} />
              <Route path="/refrrel-program" element={<RefrrelProgram />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/inbox" element={<MobileInbox />} />
              <Route path="/vip" element={<VIPConvert />} />
              <Route path="/games" element={<Games />} />
              <Route path="/gamerun" element={<GameRun />} />
              <Route path="/promotions" element={<MobilePromotion />} />
              <Route path="/chat" element={<ChatHome />} />
              <Route path="/chat-list" element={<ChatHistory />} />
              <Route path="/chat-box/:chatId" element={<ChatBox />} />
              <Route path="/odds-view" element={<MatchOdds />} />
              <Route path="/exchange" element={<EXHome />} />
              <Route path="/exchange-account" element={<ExAccount />} />
              <Route path="/exchange-inplay" element={<InPlay />} />
            </>
          ) : (
            <>
              <Route path="/" element={<DHome />} />
              <Route path="/account" element={<Account />} />
              <Route path="/casino" element={<DCasino />} />
            </>
          )}
          <Route path="/promotions" element={<Promotion />} />
          <Route path="/register" element={<Registion />} />
        </Routes>
      )}
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";

const GameViewList = ({ currentProviders }) => {
    const [games, setGames] = useState([]);
    const [error, setError] = useState(null);

    const fetchGames = async (providers) => {
        try {
            console.log("Fetching games for providers:", providers); // Log the current providers
            
            // Construct the URL based on the providers provided
            const url = providers.length > 0 
                ? `https://six6.site/api/get-games?system=${providers.join(',')}` 
                : `https://six6.site/api/get-games`; // Default request if no providers are selected

            console.log("Requesting URL:", url); // Log the URL being requested

            const response = await fetch(url);
            const data = await response.json();

            if (response.ok) {
                setGames(data.games || []);
                console.log("Fetched Games:", data); // Log the fetched games
            } else {
                console.error('Failed to fetch games:', data.message);
                setError(data.message || 'Failed to fetch games');
            }
        } catch (error) {
            console.error('Error fetching games:', error);
            setError('An unexpected error occurred while fetching games');
        }
    };

    useEffect(() => {
        fetchGames(currentProviders); // Fetch games based on the current providers
    }, [currentProviders]);

    return (
        <div className="container-1200">
            <div className="games-container">
                <ul className="total-games-group slotpage">
                    {games.map((game) => (
                        <li key={game.code} id={`game-${game.code}`} className="games-icon">
                            <div className="mask">
                                <div className="mask-inner">
                                    <span className="mask-btn-wrap">
                                        <a
                                            className="btn-default-xs playBtn"
                                            data-type={game.type}
                                            data-code={game.code}
                                            data-play=""
                                        >
                                            Play Game
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div className="tag" ui-key="tag"></div>
                            <div className="game-inner">
                                <div className="game-group">
                                    <h4 ui-key="fullName">{game.name}</h4>
                                </div>
                            </div>
                            <img
                                ui-key="gameIcon"
                                src={`./poster/${game.code}.png`} 
                                onError={(e) => { e.target.onerror = null; e.target.src = './poster/default.jpg'; }} // Fallback image
                                alt={game.platform}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default GameViewList;

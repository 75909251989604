import React, { useState } from "react";

const GameSearchAndSort = ({ onSearch, onSort }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeSort, setActiveSort] = useState("displayOrder");

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value); // Calls the onSearch function passed as a prop
  };

  const handleSort = (sortType) => {
    setActiveSort(sortType);
    onSort(sortType); // Calls the onSort function passed as a prop
  };

  return (
    <div className="top-nav-wrap">
      <div className="top-nav-inner">
        {/* Search Input */}
        <div className="search-wrap">
          <i className="search-icon"></i>
          <input
            id="gameSearch"
            type="text"
            placeholder="Search Games"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        {/* Sorting Options */}
        <ul id="game-sort-wrap" className="sort-wrap">
          <li>Sort：</li>
          <li>
            <a
              className={`btn-sort ${activeSort === "displayOrder" ? "active" : ""}`}
              onClick={() => handleSort("displayOrder")}
              data-sort="displayOrder"
            >
              Recommend
            </a>
          </li>
          <li>
            <a
              className={`btn-sort ${activeSort === "createTime" ? "active" : ""}`}
              onClick={() => handleSort("createTime")}
              data-sort="createTime"
            >
              Latest
            </a>
          </li>
          <li>
            <a
              className={`btn-sort ${activeSort === "aZ" ? "active" : ""}`}
              onClick={() => handleSort("aZ")}
              data-sort="aZ"
            >
              A-Z
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GameSearchAndSort;

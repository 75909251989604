import React from "react";

function EXHeader() {
    return(
        <header>
        <ul>
          <li className="li-tv_bet">
            <a id="openTV" className="a-open_tv ui-link" href="#" style={{display: 'none'}}><img src="./icons/exchanges/transparent.gif" /></a>
            <a id="openBetsBtn" className="a-open_bets ui-link" href="#"><img src="./icons/exchanges/transparent.gif" />Bets </a>
          </li>
          <li className="main-wallet no-multi">
            {/* 加open打開*/}
            <a id="multiWallet" href="#" className="a-wallet ui-link">
              <ul id="accountCredit">
                <li><span>Main</span><span id="betCredit">BDT 0.00</span></li>
                <li><span>Exposure</span><span id="totalExposure">0.00</span></li>
                <li className="nums">+<span id="vendorQuantity">4</span></li>
              </ul>
              <p className="loading-bar"  style={{display: 'none'}}>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </p>
            </a>
            <a className="a-refresh ui-link" id="menuRefresh" href="#" title="Refresh Main Wallet"><img src="./icons/exchanges/transparent.gif" /></a>
          </li>
          <li>
            <a className="a-setting ui-link" href="#" title="Setting">
              <img src="./icons/exchanges/transparent.gif" /></a>
          </li>
        </ul>
        <div id="msgBox" className="message-wrap success to-open_bets" style={{display: 'none'}}>
          <div className="message">
            <h4 id="header">Bet Matched</h4>
            <p id="info">
              <span id="sideType" className="back">Back</span>
              <strong id="selectionName">England</strong> <strong id="stake">$1,000</strong> at odds <strong id="odds">1.29</strong>
            </p>
          </div>
          <a id="close" className="close ui-link" href="#">Close</a>
        </div>
        <div id="multiWalletDiv" className="overlay" style={{display: 'none'}}>
          <div className="wallet-detail">
            <div className="wallet-detail-group">
              <dl className="wallet-detail-content">
                <dt>Main Balance</dt>
                <dd className="wallet-balance-num"><span className="badge-currency" id="currency">USD</span><span id="mainBalance">0.00</span></dd>
                <dd className="wallet-exposure">Exposure <span id="mainExposure">0.00</span></dd>
              </dl>
            </div>
            <div id="walletContent" className="wallet-detail-group" />
            <div id="walletTemp" className="wallet-detail-group" style={{display: 'none'}}>
              <dl id="tempDl" className="wallet-detail-content">
                <dt id="vendorTitle">Housie Balance</dt>
                <dd className="wallet-balance-num"><span className="badge-currency" id="vendorCurrency">USD</span><span id="vendorBalance">$ 0.00</span></dd>
                <dd className="wallet-recall">
                  <button className="btn-recall ui-btn ui-shadow ui-corner-all" id="recall">Recall</button>
                </dd>
              </dl>
              <dl id="recallAllDl" className="wallet-detail-content">
                <dd className="align-R">
                  <button className="btn-recall ui-btn ui-shadow ui-corner-all" id="recallAll">Recall All</button>
                </dd>
              </dl>
            </div>
            <div className="btn-box">
              <button className="btn ui-btn ui-shadow ui-corner-all" id="balanceClose">Close</button>
            </div>
          </div>
        </div>
      </header>
    )
}
export default EXHeader;
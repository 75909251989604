import React, { useState, useEffect } from 'react';
import ReferralCodePanel from './ReferralCodePanel';
import ProgramRatioBoard from './ProgramRatioBoard';
import ReferralProgramPromotionGuide from './ReferralProgramPromotionGuide';
import RewardHistoryBox from './RewardHistoryBox';

function ReferralProgram() {
  const [activeTab, setActiveTab] = useState(0); // Manage active tab index

  useEffect(() => {
    // Initialize referral program handler
    if (window.FE && window.FE.ReferralProgramHandler) {
      window.FE.docReady(window.FE.ReferralProgramHandler.init);
    }
  }, []);

  return (
    <div className="main-content-wrap">
      {/* Verification Tips */}
      <div className="tips-info verify-tips" style={{ display: 'none' }}>
        <div className="tips-title-box">
          <h6>
            <p>Please complete the verification below before you proceed with the withdrawal request.</p>
          </h6>
          <div className="close-tips"></div>
        </div>
        <ol>
          <li className="info-personal">
            <label>Personal Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Birthday</li>
              </div>
            </ul>
          </li>
          <li className="info-contact">
            <label>Contact Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Phone Number</li>
              </div>
            </ul>
          </li>
        </ol>
      </div>

      {/* Top Overview Section */}
      <div className="top-overview-wrap">
        <ul>
          <li>
            <div className="left-overview-area">
              <label>Main Wallet</label>
              <div className="total-money totalBalanceWallet">
                <span className="balance" style={{ paddingRight: '7px' }}>৳</span>
                <p className="balance">0</p>
              </div>
            </div>
          </li>
          <li style={{ display: 'none' }}>
            <div id="topOverviewTotalBonusWallet" className="left-overview-area">
              <label>Bonus Wallet</label>
              <div className="total-money totalBonusWallet">
                <span className="balance" style={{ paddingRight: '7px' }}>৳</span>
                <p>0</p>
              </div>
            </div>
          </li>
          <li>
            <div className="left-overview-area danger-state">
              <label>Security Level</label>
              <p id="contactVerifyStatus">Unverified</p>
            </div>
            <div className="right-overview-area">
              <a id="verify-mail-link" className="verify-mail status-verify-no"></a>
              <a id="verify-phone-link" className="verify-phone status-verify-no"></a>
            </div>
          </li>
        </ul>
      </div>

      {/* Main Content Body */}
      <div id="mainContentBody" className="replaceable-block">
        <main className="container-player-wrapper">
          <div className="container-player-group">
            <div className="player-title-box">
              <h2>Referral</h2>
            </div>

            <div className="main-tab-content">
              <section className="tab-pane active">
                <div className="tab-secondary-box">
                  <ul className="tabs">
                    <li onClick={() => setActiveTab(0)} className={activeTab === 0 ? 'active' : ''}>
                      <input type="radio" name="referralProgram" id="referralProgram01" checked={activeTab === 0} readOnly />
                      <label htmlFor="referralProgram01">Referral Program Intro</label>
                    </li>
                    <li onClick={() => setActiveTab(1)} className={activeTab === 1 ? 'active' : ''}>
                      <input type="radio" name="referralProgram" id="referralProgram02" checked={activeTab === 1} readOnly />
                      <label htmlFor="referralProgram02">Cash Reward History</label>
                    </li>
                  </ul>
                </div>

                {/* Tab content */}
                <div className="table-container">
                  <ul className="table-content">
                    {activeTab === 0 && (
                      <li className="active">
                        <div className="referral-info__head">
                          <div className="referral-info-banner">
                            <div className="referral-info-banner__logo"></div>
                            <div className="referral-info-banner__title"></div>
                            <div className="referral-info-banner__img">
                              <img src="https://www.jeetbuzz.com/images/v1/web/jb/referral-program/referral-program-info-banner_en.jpg" alt="referral-info-banner" />
                            </div>
                          </div>
                        </div>
                        <div className="referral-info__body">
                            <div className="referral-info-downline">
                                <div className="referral-info-downline-top">
                                    <div className="referral-info-downline__title">Active downlines</div>
                                    <div className="referral-info-downline__descrp">Daily, Weekly, Monthly Referral Requirements</div>
                                </div>
                                <div className="referral-info-downline-content">
                                    <div className="referral-info-downline__info" id="activeDownLineCount" data-descrp="Active downlines">0</div>
                                    <div className="referral-info-downline__info" id="requiredDeposit" data-descrp="Cumulative Deposit Requirement">0.00</div>
                                    <div className="referral-info-downline__info" id="requiredTurnover" data-descrp="Cumulative Turnover Requirement">0.00</div>
                                </div>
                            </div>
                            <div className="referral-info__panel referral-info-panel referral-info-downline-board"
  style={{ backgroundImage: 'url(https://www.jeetbuzz.com/images/v1/web/jb/referral-program/referral-panel-bg-2.png)' }}
>
  <div className="referral-info-downline-board__ul">
    <div className="referral-info-downline-board__li">
      <div className="referral-info-downline-board__status" id="lifeTimeCashRewards" data-title="Lifetime Cash Rewards">
        0.00
      </div>
    </div>
    <div className="referral-info-downline-board__li">
      <div className="referral-info-downline-board__status" id="referralTurnover" data-title="Referral Turnover">
        0.00
      </div>
    </div>
    <div className="referral-info-downline-board__li">
      <div
        className="referral-info-downline-board__status referral-info-downline-board__status--left"
        id="cashRewards"
        data-title="Cash Rewards"
      >
        0.00
      </div>
      <a
        className="referral-info-downline-board__btn btn-default btn-gray"
        style={{ pointerEvents: 'none', cursor: 'not-allowed' }}
        id="claimAll"
      >
        Claim All
      </a>
    </div>
  </div>
                        </div>

                        <ReferralCodePanel/>
                        <ProgramRatioBoard/>
                        <ReferralProgramPromotionGuide/>
                          
                        </div>
                      </li>
                    )}

                    {activeTab === 1 && (
                     <RewardHistoryBox/>
                    )}
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ReferralProgram;

import React from "react";

function DepositNotice() {
  return (
    <div className="ng-trigger ng-trigger-slideAnimation ng-star-inserted">
    <div className="tips-info verify-tips tips-info-toggle ng-star-inserted">
        <div className="title-box">
            <h5>
                <i className="tips-icon" style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-tips-type02.svg?v=1729676340443")'}} />
                <span>Below info are required to proceed deposit request.</span>
            </h5>
            <div className="toggle-btn ng-star-inserted" style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")'}} />
        </div>
        <ol className="tips-info-block active">
            <li className="contact-info ng-star-inserted">
                <a className="ng-star-inserted">
                    <label>Contact Info</label>
                    <ul>
                        <li className="ng-star-inserted">Phone Number</li>
                    </ul>
                </a>
            </li>
        </ol>
    </div>
</div>
  );
}
export default DepositNotice;
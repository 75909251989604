import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function MainNav() {
  return (
    <div className="main-nav main-sub-nav">
      <ul className="nav-inner">
        <li className="nav-item back-index active" id="menu-index">
          <Link className="nav-promotion" to="/">
            <i className="icon-home"></i>
          </Link>
        </li>
        <li className="nav-item back-index active">
          <Link to="/page/site/component/appDownload.jsp" target="_blank" rel="noopener noreferrer">
            <i className="icon-app"></i>
          </Link>
        </li>
        <li className="nav-item" id="menu-sport">
          <Link>
            Sports
            <i className="icon-angle-down"></i>
          </Link>
          <div className="sub-nav-slide" id="lv2-menu-sport">
            <div className="sub-nav-inner">
              <button className="arrow-prev" disabled></button>
              <div className="sub-nav-draggable">
                <div className="sub-nav-track">
                  <div className="sub-nav-item">
                    <span>Exchange</span>
                    <Link to="/player/playGame?t=sport&v=CRICKET">
                      <div className="item-box">
                        <img
                          className="item-1st"
                          src="https://www.jeetbuzz.com/images/web/nav/subnav-slide/exchange_bdt_01.png"
                          alt="CRICKET Betting"
                        />
                        <img
                          className="item-2nd"
                          src="https://www.jeetbuzz.com/images/web/nav/subnav-slide/exchange_bdt_02.png"
                          alt="CRICKET Betting"
                        />
                        <img
                          className="item-3rd"
                          src="https://www.jeetbuzz.com/images/web/nav/subnav-slide/exchange_bdt_03.png"
                          alt="CRICKET Betting"
                        />
                      </div>
                      <p>Play Now</p>
                    </Link>
                  </div>
                  {/* Add more sub-nav items as necessary */}
                </div>
              </div>
              <button className="arrow-next"></button>
            </div>
          </div>
        </li>
        {/* Other navigation items */}
        <li className="nav-item" id="menu-casino">
          <Link className="nav-promotion" to="/casino">Casino</Link>
        </li>
        <li className="nav-item" id="menu-slot">
          <Link className="nav-promotion" to="/slot">Slot</Link>
        </li>
        <li className="nav-item" id="menu-table">
          <Link className="nav-promotion" to="/table">Table</Link>
        </li>
        <li className="nav-item" id="menu-crash">
          <Link className="nav-promotion" to="/crash">Crash</Link>
        </li>
        <li className="nav-item" id="menu-fish">
          <Link className="nav-promotion" to="/fishing">Fishing</Link>
        </li>
        <li className="nav-item" id="menu-arcade">
          <Link className="nav-promotion" to="/arcade">Arcade</Link>
        </li>
        <li className="nav-item" id="menu-lottery">
          <Link className="nav-promotion" to="/lottery">Lottery</Link>
        </li>
        <li className="nav-item" id="menu-promotions">
          <Link className="nav-promotion" to="/promotions">Promotions</Link>
        </li>
        <li className="nav-item" id="menu-vip">
          <Link className="nav-promotion" to="/vip">VIP</Link>
        </li>
        <li className="nav-item" id="menu-rafCommission">
          <Link className="nav-promotion" to="/referral">Referral</Link>
        </li>
      </ul>
    </div>
  );
}

export default MainNav;

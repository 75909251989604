import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PromotionNav = () => {
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <button aria-label="Next" className="slick-next slick-arrow">Next</button>,
    prevArrow: <button aria-label="Previous" className="slick-prev slick-arrow">Previous</button>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const filters = [
    { id: "all", label: "All", filter: "all" },
    { id: "99", label: "Welcome Offer", filter: "99" },
    { id: "1", label: "Slots", filter: "1" },
    { id: "2", label: "Casino", filter: "2" },
    { id: "4", label: "Sports", filter: "4" },
    { id: "8", label: "Fishing", filter: "8" },
    { id: "16", label: "Card Game", filter: "16", hidden: true },
    { id: "32", label: "ESports", filter: "32" },
    { id: "64", label: "Lottery", filter: "64" },
    { id: "128", label: "P2P", filter: "128" },
    { id: "256", label: "Table", filter: "256" },
    { id: "1024", label: "Arcade", filter: "1024" },
    { id: "2048", label: "Cock Fighting", filter: "2048", hidden: true },
    { id: "4096", label: "Rain", filter: "4096", hidden: true },
    { id: "8192", label: "Crash", filter: "8192" },
    { id: "16384", label: "ui.text.fe.game_type.free", filter: "16384", hidden: true },
    { id: "512", label: "Others", filter: "512" },
  ];

  return (
    <div className="promotion-nav promotion-nav-wallet">
      <div className="promotion-nav-inner">
        <div className="promotion-filter-box">
          <Slider {...sliderSettings} className="slides slick-initialized slick-slider" id="promoFilter">
            {filters.map((filter, index) =>
              !filter.hidden ? (
                <div
                  key={filter.id}
                  data-filter={filter.filter}
                  className={`slick-slide ${index === 0 ? "slick-current slick-active" : ""}`}
                  data-slick-index={index}
                >
                  <a className={`btn-default-radius ${index === 0 ? "active" : ""}`}>
                    {filter.label}
                  </a>
                </div>
              ) : null
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default PromotionNav;

import React from "react";
import { Link } from "react-router-dom";

function UniversalBack() {
    return (
       
    <Link to={'/account'} className="bg-tran">
        <div className="back-btn" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")' }} />
    </Link>
            
        
    );
}
export default UniversalBack;
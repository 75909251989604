import React, { useState, useEffect } from 'react';

const Inbox = () => {
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [inboxData, setInboxData] = useState([
    {
      id: '1094978790',
      title: 'Sign up success.',
      content: 'Congratulations! You have successfully signed up! Get ready to experience the excitement of our platform.',
      receivedDate: '2024/10/26 21:51:34',
      isNew: true,
    },
  ]);
  const [editingId, setEditingId] = useState(null);
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    if (window.FE && window.FE.InboxHandler) {
      window.FE.docReady(window.FE.InboxHandler.init);
    }
  }, []);

  const handleRecordsChange = (event) => {
    setRecordsPerPage(Number(event.target.value));
  };

  const handleEditClick = (id, content) => {
    setEditingId(id);
    setEditedContent(content);
  };

  const handleSaveClick = (id) => {
    setInboxData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, content: editedContent } : item
      )
    );
    setEditingId(null); // Exit edit mode
  };

  const handleDeleteClick = (id) => {
    setInboxData((prevData) => prevData.filter((item) => item.id !== id));
    setEditingId(null); // Exit edit mode if the current edited item is deleted
  };

  const handleExitEditMode = () => {
    setEditingId(null); // Just exit edit mode without saving
  };

  return (
    <div id="mainContentBody" className="replaceable-block">
      <div className="container-player-group">
        <div className="player-title-box">
          <h2>
            <span>Inbox</span>
          </h2>
        </div>
        <div className="tab-secondary-box">
          <ul className="tabs">
            <li className="active">
              <input type="radio" name="mail" id="notifyInbox" defaultChecked />
              <label htmlFor="notifyInbox">
                Notification&nbsp;<span id="inboxMsgCount">{inboxData.length}</span>
              </label>
            </li>
          </ul>
        </div>

        <div className="table-change-wrap">
          <div className="table-container">
            <ul className="table-content main-tab-content">
              <li className="active">
                <div id="DataTables_Table_15_wrapper" className="dataTables_wrapper no-footer">
                  <div className="dataTables_length" id="DataTables_Table_15_length">
                    <label>
                      <select value={recordsPerPage} onChange={handleRecordsChange}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select> records per page
                    </label>
                  </div>

                  <table className="table-box display mail-table dataTable no-footer" id="DataTables_Table_15" role="grid" aria-describedby="DataTables_Table_15_info">
                    <thead>
                      <tr className="table-title">
                        <th className="column-m sorting_disabled" style={{ width: '240px' }}>
                          Subject
                        </th>
                        <th className="column-l sorting_disabled" style={{ width: '379px' }}>
                          Content
                        </th>
                        <th className="column-time sorting_desc" style={{ width: '182px' }}>
                          Received Date <span id="timeZoneText">GMT+6</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody id="inbox_container">
                      {inboxData.length > 0 ? (
                        inboxData.map((item) => (
                          <tr key={item.id} className={item.isNew ? 'new' : ''} data-id={item.id} title={item.title} role="row">
                            <td>
                              <input type="checkbox" data-id={item.id} id={`inbox-${item.id}`} />
                              <label htmlFor={`inbox-${item.id}`} ui-key="title">
                                {item.title}
                              </label>
                            </td>
                            <td>
                              {editingId === item.id ? (
                                <>
                                  <input
                                    type="text"
                                    value={editedContent}
                                    onChange={(e) => setEditedContent(e.target.value)}
                                  />
                                  <button onClick={() => handleSaveClick(item.id)}>Save</button>
                                </>
                              ) : (
                                <div ui-key="showMessage" className="mail-content">
                                  {item.content}
                                  <button onClick={() => handleEditClick(item.id, item.content)}>Edit</button>
                                </div>
                              )}
                            </td>
                            <td className="column-time sorting_1" ui-key="createTime" style={{ backgroundColor: 'unset' }}>
                              {item.receivedDate}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="dataTables_empty">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="dataTables_info" id="DataTables_Table_15_info" role="status" aria-live="polite">
                    Showing 1 to {recordsPerPage} of {inboxData.length} entries
                  </div>

                  <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_15_paginate">
                    <a className="paginate_button previous disabled" aria-controls="DataTables_Table_15" data-dt-idx="0" tabIndex="-1" id="DataTables_Table_15_previous">
                      Previous
                    </a>
                    <span>
                      <a className="paginate_button current" aria-controls="DataTables_Table_15" data-dt-idx="1" tabIndex="0">
                        1
                      </a>
                    </span>
                    <a className="paginate_button next disabled" aria-controls="DataTables_Table_15" data-dt-idx="2" tabIndex="-1" id="DataTables_Table_15_next">
                      Next
                    </a>
                  </div>
                </div>

                <div className="table-button-box">
  <div className={`need-edit-mail ${editingId === null ? 'active' : ''}`}>
    <button className="btn-player-secendary btn-5th-xs" onClick={handleEditClick}>
      Edit
    </button>
  </div>
  <div className={`edit-mail ${editingId !== null ? 'active' : ''}`}>
    <button className="btn-player-secendary btn-2nd-xs choose-delete" onClick={() => handleDeleteClick(editingId)}>
      Delete
    </button>
    <button className="btn-player-secendary btn-gray-xs edit-cancel" onClick={handleExitEditMode}>
      Exit
    </button>
  </div>
</div>

              </li>

              <div style={{ display: 'none' }}>
                <table>
                  <tbody>
                    <tr id="inbox_template" className="new" data-id="" data-messagetype="">
                      <td>
                        <input type="checkbox" data-id="" id="temp" />
                        <label htmlFor="temp" ui-key="title">-</label>
                      </td>
                      <td>
                        <div ui-key="contentMessage" style={{ display: 'none' }}>-</div>
                        <div ui-key="showMessage" className="mail-content">-</div>
                      </td>
                      <td className="column-time" ui-key="createTime" style={{ backgroundColor: 'unset' }}>
                        yyyy/MM/dd hh:mm:ss
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inbox;

import React from "react";
import { Link, useLocation } from "react-router-dom";

function EXNav() {
  const location = useLocation(); // Get the current location

  // Utility function to check if the current route matches
  const isActive = (path) => location.pathname === path;

  return (
    <nav>
      <ul className="mini-lobby">
        <li id="mini" className="main-nav">
          <a className="ui-link"></a>
        </li>
        <li className={isActive('/exchange') ? "select" : ""}>
          <Link to={'/exchange'} className="ui-link">
            <img className="icon-home" src="./icons/exchanges/transparent.gif" alt="Home Icon" />
            Home
          </Link>
        </li>
        <li id="inPlay" className={isActive('/exchange-inplay') ? "select" : ""}>
          <Link to={'/exchange-inplay'} className="ui-link">
            <img className="icon-inplay" src="./icons/exchanges/transparent.gif" alt="In-Play Icon" />
            In-Play
          </Link>
        </li>
        <li id="sports" className={isActive('/exchange-sports') ? "select" : ""}>
          <Link to={'/exchange-sports'} className="ui-link">
            <img className="icon-sports" src="./icons/exchanges/transparent.gif" alt="Sports Icon" />
            Sports
          </Link>
        </li>
        <li id="multiMarket" className={isActive('/exchange-multi') ? "select" : ""}>
          <Link to={'/exchange-multi'} className="ui-link">
            <img className="icon-pin" src="./icons/exchanges/transparent.gif" alt="Multi Markets Icon" />
            Multi Markets
          </Link>
        </li>
        <li id="account" className={isActive('/exchange-account') ? "select" : ""}>
          <Link to={'/exchange-account'} className="ui-link">
            <img className="icon-account" src="./icons/exchanges/transparent.gif" alt="Account Icon" />
            Account
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default EXNav;

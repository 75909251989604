import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function ChatTongole() {
    return (
        <Link to={'/chat'}>
        <div className="chat-tongole">
            
            <FontAwesomeIcon className="text-white" icon={faPaperPlane}/>
            
            </div>
            </Link>
    );
}
export default ChatTongole;
import React from 'react';

const FancyBetTable = ({ fancyBets }) => {
  return (
    <div id="fancyBetTable_-10743129" style={{}} name="multiMarketItem">
    <div id="fancyBetHead" className="fancy_bet-head" style={{}}>
        {/*a id="multiMarketPin" class="pin-off" href="#">Pin to Muilti Markets</a*/}
        <h4 className="in-play">
            <a id="multiMarketPin" className="pin-off" href="#">
                Pin to Muilti Markets
            </a>
            <span>
                <pre>in-play</pre>
                Fancy Bet
            </span>
            <a className="btn-head_rules" onclick="javascript: $j('#fancyBetRulesWrap').fadeIn().css('display', 'flex');">
                Rules
            </a>
        </h4>
    </div>
    <div id="fancyBetTabWrap" className="fancy_bet_tab-wrap ps ps--theme_default ps--active-x" style={{}} data-ps-id="291e9b78-8440-a84e-39e0-e07dc4e47af4">
        <ul className="special_bets-tab">
            <li className="select">
                <a fancybettabtype={1}>All</a>
            </li>
            <li>
                <a fancybettabtype={2}>Fancy</a>
            </li>
            <li>
                <a fancybettabtype={6}>Ball by Ball</a>
            </li>
            <li>
                <a fancybettabtype={3}>Khadda</a>
            </li>
            <li>
                <a fancybettabtype={4}>Lottery</a>
            </li>
            <li>
                <a fancybettabtype={5}>Odd/Even</a>
            </li>
        </ul>
        <div className="ps__scrollbar-x-rail" style={{ width: "375px", left: "0px", bottom: "0px" }}>
            <div className="ps__scrollbar-x" tabIndex={0} style={{ left: "0px", width: "368px" }} />
        </div>
        <div className="ps__scrollbar-y-rail" style={{ top: "0px", right: "0px" }}>
            <div className="ps__scrollbar-y" tabIndex={0} style={{ top: "0px", height: "0px" }} />
        </div>
    </div>

    <div id="fancyBetField" className="bets-wrap fancy-bet" style={{}}>
        <dl className="bets-selections-head">
            <dt />
            <dd className="mode-land" />
            <dd className="mode-land" />
            <dd>No</dd>
            <dd>Yes</dd>
            <dd className="mode-land" />
            <dd className="mode-land" />
        </dl>
    </div>
    <div id="fancyBetTag">
    {fancyBets && fancyBets.length > 0 ? (
            fancyBets.map((bet, index) => (
        <div id="fancyBetMarket_1877961" className="bets-wrap fancy-bet" style={{}}>
            <h5>
                <span id="marketName">{bet.title}</span>
                <a href="#" id="open-fancy_info" className="btn-fancy_info">
                    fancybet info
                </a>
                <div id="fancy_popup2" className="fancy_info-popup">
                    <dl>
                        <dt>Min / Max</dt>
                        <dd id="minMax">74.00 / 18,594.00</dd>
                    </dl>
                    <dl>
                        <dt id="rebateName" style={{ display: "none" }}>
                            Rebate
                        </dt>
                        <dd id="rebate" style={{ display: "none" }} />
                    </dl>
                    <a href="#" id="close-fancy_info" className="close">
                        Close
                    </a>
                </div>
            </h5>
            <dl className="bets-selections">
                <dt className="line_market-selection">
                    <dl className="tips">
                        <dt>
                            <span id="before" className="win" style={{ display: "none" }} />
                            <span id="after" className="to-lose" style={{ display: "none" }} />
                        </dt>
                        <dd id="remarkFirstRow" style={{ display: "none" }} />
                        <dd id="remarkSecondRow" style={{ display: "none" }} />
                    </dl>
                    <a id="fancyBetBookBtn" href="#" className="btn-book" style={{ display: "none" }}>
                        Book
                    </a>
                </dt>
                {/* Suspend for Fancy Bet */}
                <dd  className={`${bet.suspended === 1 || bet.ball_running === 1  ? 'suspend-fancy' : ''}`} style={{}}>
                {bet.suspended === 1 ? (
                    <p>Suspend</p>
                    ) : bet.ball_running === 1 ? (
                    <p>Ball Running</p>
                    ) : null}

                   
                </dd>
                <dd id="delayBetting" className="suspend-fancy" style={{ display: "none" }}>
                    <p id="info" />
                </dd>
                <dd id="back_1">
                    <a className="back-1" href="#">
                    {bet.no}<span>({bet.no_rate})</span>
                    </a>
                </dd>
                <dd id="lay_1">
                    <a className="lay-1" href="#">
                    {bet.yes}<span>({bet.yes_rate})</span>
                    </a>
                </dd>
                
                <dd className="mode-land" />
                <dd className="mode-land" />
            </dl>
        </div>
         ))
        ) : (
          <tr>
            <td colSpan="3">No fancy bets available</td>
          </tr>
        )}
    </div>
</div>
  );
};

export default FancyBetTable;
